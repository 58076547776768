import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "Player__flex" }
const _hoisted_3 = {
  class: "Player__flex-left",
  "data-shaka-player-container": "",
  ref: "containerRef"
}
const _hoisted_4 = {
  key: 0,
  ref: "videoPlayerRef",
  class: "video-js Player__video",
  width: "100%",
  autoplay: "",
  disablePictureInPicture: "",
  controlslist: "nofullscreen"
}
const _hoisted_5 = {
  key: 1,
  class: "Player__thumbnail"
}
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_not_found = _resolveComponent("not-found")!
  const _component_not_supported_browser = _resolveComponent("not-supported-browser")!
  const _component_not_subscribe = _resolveComponent("not-subscribe")!
  const _component_PlayerChat = _resolveComponent("PlayerChat")!
  const _component_PlayerInformation = _resolveComponent("PlayerInformation")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.notFound === null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (_ctx.notFound)
        ? (_openBlock(), _createBlock(_component_not_found, { key: 1 }))
        : (!_ctx.supportedBrowser)
          ? (_openBlock(), _createBlock(_component_not_supported_browser, { key: 2 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              (_ctx.isAvailableContents === false)
                ? (_openBlock(), _createBlock(_component_not_subscribe, {
                    key: 0,
                    content: _ctx.content,
                    sellEndAt: _ctx.sellEndAt,
                    status: _ctx.status,
                    subscribed: _ctx.subscribed,
                    isRestrictContents: _ctx.isRestrictContents
                  }, null, 8, ["content", "sellEndAt", "status", "subscribed", "isRestrictContents"]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["Player", {
            'Player--info': _ctx.mode === 'info',
            'is-portrait': _ctx.store.state.orientation.isPortrait,
            'is-landscape': !_ctx.store.state.orientation.isPortrait,
          }])
                  }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        (_ctx.canPlay)
                          ? (_openBlock(), _createElementBlock("video", _hoisted_4, null, 512))
                          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createElementVNode("img", {
                                src: (_ctx.content.images || {}).image
                              }, null, 8, _hoisted_6)
                            ]))
                      ], 512),
                      (_ctx.withChat)
                        ? (_openBlock(), _createBlock(_component_PlayerChat, {
                            key: 0,
                            content: _ctx.content,
                            mode: _ctx.mode,
                            onUpdateMode: _ctx.updateMode
                          }, null, 8, ["content", "mode", "onUpdateMode"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_component_PlayerInformation, {
                      mode: _ctx.mode,
                      withChat: _ctx.withChat,
                      content: _ctx.content,
                      download: _ctx.download,
                      sellEndAt: _ctx.sellEndAt,
                      onUpdateMode: _ctx.updateMode
                    }, null, 8, ["mode", "withChat", "content", "download", "sellEndAt", "onUpdateMode"])
                  ], 2))
            ], 64))
  ]))
}