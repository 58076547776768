
import { defineComponent, onMounted, onUnmounted, ref, nextTick } from "vue";
import { useStore } from "vuex";

import { db } from "@/utils/firebase";
import { addChatMessageFunctions } from "@/utils/firebase/functions";
import {
  useContentId,
  doc2data,
  useNickname,
  useUid,
  timeFormat,
} from "@/utils/utils";

export default defineComponent({
  name: "ShareButtons",
  setup() {
    const messageDetacher = ref();
    const messages = ref([]);

    const contentId = useContentId();
    const nickname = useNickname();
    const uid = useUid();

    const chatMessage = ref("");
    const store = useStore();

    const scrollMessages = () => {
      nextTick(() => {
        // SP表示では動的にChatの高さを指定する必要あり
        const el = document.querySelector(".Chat__body");
        if (store.state.isMobile) {
          const offsetTop = el.getBoundingClientRect().top + window.pageYOffset;
          (el as any).style.height = window.innerHeight - offsetTop + "px";
        }
        el.scrollTop = el.scrollHeight;
      });
    };

    onMounted(() => {
      messageDetacher.value = db
        .collection(`contents/${contentId.value}/messages`)
        .where("deletedFlag", "==", false)
        .orderBy("at", "desc")
        .limit(200)
        .onSnapshot((result) => {
          messages.value = result.docs.map(doc2data()).reverse();
          scrollMessages();
        });
    }),
      onUnmounted(() => {
        if (messageDetacher.value) {
          messageDetacher.value();
          messageDetacher.value = null;
        }
      });
    const addMessage = async (message) => {
      if (message && message.length > 1) {
        try {
          await addChatMessageFunctions({
            message,
            nickname: nickname.value,
            contentId: contentId.value,
          });
        } catch (e) {
          console.log({});
        }
      }
    };
    const enter = async () => {
      const message = chatMessage.value;
      chatMessage.value = "";
      const id = db.collection("dummy").doc().id;
      const dummy = {
        message,
        nickname: nickname.value,
        id,
        uid: uid.value,
        at: {
          seconds: new Date().getTime() / 1000,
        },
      };
      messages.value.push(dummy);
      scrollMessages();
      await addMessage(message);
    };
    return {
      chatMessage,
      chatMessageIsVisible: true, // SP表示、横位置における表示切り替え用
      messageDetacher: null,
      messages,
      enter,
      timeFormat,
      scrollMessages,
    };
  },
});
