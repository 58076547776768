
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

import { useStore } from "vuex";

import ShareButtons from "@/components/ShareButtons.vue";
import Autolink from "@/components/Autolink.vue";
import moment from "moment";

export default defineComponent({
  props: {
    content: {
      type: Object,
      required: true,
    },
    download: {
      type: Object,
      required: false,
    },
    withChat: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    sellEndAt: {
      type: Object,
      required: true,
    },
  },
  components: {
    ShareButtons,
    Autolink,
  },
  emits: ["updateMode"],
  setup(props, context) {
    const store = useStore();
    const isInformationVisible = ref(false); // SP横画面表示用

    const setInformationVisible = () => {
      if (store.state.orientation.isPortrait || props.withChat) {
        isInformationVisible.value = true;
      } else {
        isInformationVisible.value = false;
      }
    };

    const hideVideoOverlay = () => {
      if (store.state.isMobile && !store.state.orientation.isPortrait) {
        if (props.withChat) {
          context.emit("updateMode", "chat");
        } else {
          isInformationVisible.value = false;
        }
      }
    };

    onMounted(() => {
      setInformationVisible();
      window.addEventListener(
        "orientationchange",
        setInformationVisible,
        false,
      );
    });

    onUnmounted(() => {
      window.removeEventListener(
        "orientationchange",
        setInformationVisible,
        false,
      );
    });
    return {
      hideVideoOverlay,
      isInformationVisible,
      moment,
      store,
    };
  },
});
