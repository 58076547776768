
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { auth } from "@/utils/firebase";

export default defineComponent({
  async created() {
    const route = useRoute();
    const router = useRouter();

    try {
      const res = await auth.applyActionCode(route.query.oobCode as string);
      console.log(res);
    } catch (e) {
      console.log("error");
    }
    router.push("/account/mypage");
  },
});
