import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10476837"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "FormItem" }
const _hoisted_2 = {
  key: 0,
  class: "FormItem__label"
}
const _hoisted_3 = {
  key: 0,
  class: "FormItem__required"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "（必須）"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}