import { functions } from "@/utils/firebase9";
import { httpsCallable } from "firebase/functions";

export const getContentsKeyFunctions = httpsCallable(
  functions,
  "getContentsKey",
);

export const confirmRestrectEmailFunctions = httpsCallable<
  {
    contentId: string;
  },
  {
    result: boolean;
  }
>(functions, "confirmRestrectEmail");

export const addChatMessageFunctions = httpsCallable(
  functions,
  "addChatMessage",
);

export const emailFunctions = httpsCallable(functions, "email");

export const lineValidateFunctions = httpsCallable<
  {
    code: string;
    redirectUri: string;
    clientId: string;
  },
  {
    token: string;
    nonce: string;
  }
>(functions, "lineValidate");

export const deleteSourceFunctions = httpsCallable(functions, "deleteSource");

export const SubscribeContentsWithTokenFunctions = httpsCallable<
  {
    sourceId?: string;
    save?: string;
    reuse?: number;
    rightsId: string;
    debug: number;
  },
  { result: boolean }
>(functions, "SubscribeContentsWithToken");

export const SubscribeContentsWithTicketFunctions = httpsCallable<
  {
    userTicketId: string;
    rightsId: string;
  },
  { result: boolean }
>(functions, "SubscribeContentsWithTicket");

export const getDownloadUrlFunctions = httpsCallable<
  {
    contentId: string;
  },
  {
    result: boolean;
    data: {
      url: string;
    };
  }
>(functions, "getDownloadUrl");
