
import { defineComponent, computed } from "vue";

import ShareButton from "@/components/ShareButton.vue";
import moment from "moment";

export default defineComponent({
  name: "ShareButtons",
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const url = computed(() => {
      if (props.content && props.content.id) {
        return (
          location.protocol +
          "//" +
          location.host +
          "/live/" +
          props.content.livehousename +
          "/" +
          moment(props.content.liveStartAt.toDate()).format("YYYYMMDD") +
          "/" +
          props.content.id
        );
      }
      return "";
    });
    const title = computed(() => {
      if (props.content && props.content.name) {
        return encodeURIComponent(props.content.name);
      }
      return "";
    });
    return {
      url,
      title,
    };
  },
  components: {
    ShareButton,
  },
});
