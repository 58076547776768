import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61346b9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "HeaderNav" }
const _hoisted_2 = { class: "HeaderNav__body" }
const _hoisted_3 = { class: "HeaderNav__item" }
const _hoisted_4 = { class: "HeaderNav__item" }
const _hoisted_5 = {
  key: 0,
  class: "HeaderNav__item"
}
const _hoisted_6 = { class: "HeaderNav__item" }
const _hoisted_7 = { class: "HeaderNav__item" }
const _hoisted_8 = { class: "HeaderNav__item" }
const _hoisted_9 = { class: "HeaderNav__item" }
const _hoisted_10 = { class: "HeaderNav__item" }
const _hoisted_11 = { class: "HeaderNav__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["HeaderNav__veil", { 'is-active': _ctx.active }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, null, 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "HeaderNav__link",
          to: "/"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Home")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: "HeaderNav__link",
          to: "/live/lists/sports"
        }, {
          default: _withCtx(() => [
            _createTextVNode("List")
          ]),
          _: 1
        })
      ]),
      (_ctx.authenticated)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              class: "HeaderNav__link",
              to: "/account/mypage"
            }, {
              default: _withCtx(() => [
                _createTextVNode("マイページ")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, {
          class: "HeaderNav__link",
          to: "/docs/terms"
        }, {
          default: _withCtx(() => [
            _createTextVNode("利用規約")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, {
          class: "HeaderNav__link",
          to: "/docs/privacy"
        }, {
          default: _withCtx(() => [
            _createTextVNode("プライバシーポリシー")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_router_link, {
          class: "HeaderNav__link",
          to: "/docs/law"
        }, {
          default: _withCtx(() => [
            _createTextVNode("特定商取引法表示")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_router_link, {
          class: "HeaderNav__link",
          to: "/docs/requirements"
        }, {
          default: _withCtx(() => [
            _createTextVNode("推奨環境")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_router_link, {
          class: "HeaderNav__link",
          to: "/docs/copyright"
        }, {
          default: _withCtx(() => [
            _createTextVNode("著作権事業者番号")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_router_link, {
          class: "HeaderNav__link",
          to: "/docs/faq"
        }, {
          default: _withCtx(() => [
            _createTextVNode("よくある質問")
          ]),
          _: 1
        })
      ])
    ])
  ]))
}