
import { defineComponent } from "vue";

import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { auth } from "@/utils/firebase9";
import { signOut } from "firebase/auth";
import { topPage, useLoginLink, useUser } from "@/utils/utils";

export default defineComponent({
  name: "UserButton",
  setup() {
    const router = useRouter();
    const store = useStore();

    const loginLink = useLoginLink();
    const user = useUser();

    const signout = async () => {
      // console.log("signing out", auth.currentUser);
      try {
        signOut(auth);
        store.commit("setDbUser", undefined);
        store.commit("setUser", undefined);

        // TODO
        if (location.pathname !== topPage) {
          router.push(topPage);
        }
      } catch (error) {
        console.log("sign out failed", error);
      }
    };
    return {
      signout,
      loginLink,
      user,
    };
  },
});
