
import { defineComponent } from "vue";

import ShareButtons from "@/components/ShareButtons.vue";
import Autolink from "@/components/Autolink.vue";
import PurchaseButtons from "@/components/PurchaseButtons.vue";
import moment from "moment";

export default defineComponent({
  components: {
    ShareButtons,
    Autolink,
    PurchaseButtons,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    sellEndAt: {
      type: Object,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    subscribed: {
      type: Boolean,
      required: true,
    },
    isRestrictContents: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return { moment };
  },
});
