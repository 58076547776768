
import { defineComponent, onUnmounted, ref, watch, computed } from "vue";

import { db } from "@/utils/firebase9";
import {
  doc,
  getDoc,
  getDocs,
  orderBy,
  collection,
  query,
  onSnapshot,
  where,
  limit,
  DocumentData,
} from "firebase/firestore";

import LiveList from "@/components/common/LiveList.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import Category from "@/components/common/Category.vue";
import SearchBox from "@/components/common/SearchBox.vue";

import { useUser, useUid, doc2data, useCategoryIds } from "@/utils/utils";
import { useHead } from "@vueuse/head";

export default defineComponent({
  name: "ContentList",
  components: {
    LiveList,
    PageTitle,
    Category,
    SearchBox,
  },
  setup() {
    const user = useUser();
    const uid = useUid();
    let detacher = null;
    let rDetacher = null;
    const contents = ref([]);
    const rights = ref([]);

    const searchInput = ref("");
    const subCategoryData = ref([]);

    const categories = ref([]);
    const selectedSubCategory = ref<DocumentData | null>(null);

    useHead({
      title: "LiveMedia Space - 動画ストリーミングサービス",
    });

    const { categoryId, subCategoryId, subSubCategoryId } = useCategoryIds();

    (async () => {
      categories.value = (
        await getDocs(
          query(
            collection(db, `categories`),
            orderBy("order", "asc"),
            limit(20),
          ),
        )
      ).docs.map(doc2data());
    })();

    const selectedCategory = computed(() => {
      if (categoryId.value) {
        return categories.value.find((cat) => {
          return cat.id === categoryId.value;
        });
      }
      return null;
    });
    if (user.value) {
      rDetacher = onSnapshot(
        query(collection(db, `users/${uid.value}/rights`), limit(20)),
        (result) => {
          rights.value = result.docs.map(doc2data()).reverse();
        },
      );
    }

    const getSubCat = async () => {
      if (subCategoryId.value) {
        const data = (
          await getDoc(
            doc(
              db,
              `categories/${categoryId.value}/subcategories/${subCategoryId.value}`,
            ),
          )
        ).data();
        subCategoryData.value = (data?.description || "").split("\n");
        selectedSubCategory.value = data;
      } else {
        subCategoryData.value = [];
        selectedSubCategory.value = null;
      }
    };
    watch(subCategoryId, () => {
      getSubCat();
    });
    getSubCat();

    // live category just sort by liveStartAt. others not sorted.
    const myQuery = computed(() => {
      if ((categoryId.value, subCategoryId.value, subSubCategoryId.value)) {
        return query(
          collection(db, `contents`),
          where("inList", "==", true),
          where("category", "==", categoryId.value),
          where("subcategory", "==", subCategoryId.value),
          where("subsubcategory", "==", subSubCategoryId.value),
          limit(2000),
        );
      }
      if (selectedCategory.value && selectedCategory.value["noSubcategory"]) {
        // if (selectedCategory.value.id === "event" || selectedCategory.value.id === "sports") {
        return query(
          collection(db, `contents`),
          where("inList", "==", true),
          where("category", "==", categoryId.value),
          orderBy("liveStartAt", "desc"),
          limit(2000),
        );
        /*        
        } else {
          return query(
            collection(db, `contents`),
            where("inList", "==", true),
            where("category", "==", categoryId.value),
            limit(2000),
          );
          }
        */
      }
      if (
        selectedCategory.value &&
        selectedSubCategory.value &&
        selectedSubCategory.value["noSubSubcategory"]
      ) {
        return query(
          collection(db, `contents`),
          where("inList", "==", true),
          where("category", "==", categoryId.value),
          where("subcategory", "==", subCategoryId.value),
          orderBy("liveStartAt", "desc"),
          limit(2000),
        );
      }
      return null;
    });
    const updateQuery = () => {
      if (detacher) {
        detacher();
        detacher = null;
      }
      if (myQuery.value) {
        detacher = onSnapshot(myQuery.value, (result) => {
          contents.value = result.docs
            .map(doc2data())
            .filter((content) => {
              // console.log(categoryId.value, subCategoryId.value, content);
              if (
                content.PublishEndAt &&
                content.PublishEndAt.toDate().getTime() < new Date().getTime()
              ) {
                return false;
              }
              return true;
            })
            .sort((a, b) => {
              return a.order > b.order ? 1 : -1;
            });
        });
      } else {
        contents.value = [];
      }
    };

    const searchResult = computed(() => {
      if (searchInput.value !== "") {
        return contents.value.filter((content) => {
          const words = searchInput.value
            .split(/\s/)
            .filter((word) => word !== "");

          return words.every((word: string) => {
            if (content.name.indexOf(word) > -1) {
              return true;
            }
            return false;
          });
        });
      }
      return [];
    });
    watch(myQuery, () => {
      updateQuery();
    });
    updateQuery();

    onUnmounted(() => {
      if (rDetacher) {
        rDetacher();
        rDetacher = null;
      }
      if (detacher) {
        detacher();
        detacher = null;
      }
    });

    return {
      contents,
      rights,
      searchInput,
      searchResult,
      subCategoryData,
      categoryId,

      categories,
      selectedCategory,
    };
  },
});
