
import { defineComponent } from "vue";

export default defineComponent({
  name: "ShareButton",
  props: {
    service: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
});
