
import { defineComponent, ref } from "vue";

// https://firebase.googleblog.com/2016/11/authenticate-your-firebase-users-with-line-login.html
import { lineConfig } from "@/config/project";
import { auth } from "@/utils/firebase";
import { lineValidateFunctions } from "@/utils/firebase/functions";
import { lineGuard } from "@/utils/line";

import { useRouter, useRoute } from "vue-router";

import { useAuthenticated, topPage } from "@/utils/utils";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const authenticated = useAuthenticated();

    const isValidating = ref(false);
    const isError = ref(false);
    const isEmailError = ref(false);

    if (authenticated) {
      router.push(topPage);
    }
    const code = route.query.code as string;
    const state = route.query.state as string;
    const redirectUri = location.origin + "/callback/line";

    if (code) {
      (async () => {
        try {
          isValidating.value = true;
          const { data } = await lineValidateFunctions({
            code,
            redirectUri: redirectUri,
            clientId: lineConfig.loginChannelId,
          });

          if (data.nonce && data.token) {
            const params = lineGuard(data.nonce, state);
            await auth.signInWithCustomToken(data.token);
            if (params.redirectPath) {
              router.push(params.redirectPath);
            } else {
              router.push(topPage);
            }
          } else {
            console.error("validatin failed", data);
            throw new Error("something is wrong");
          }
        } catch (error) {
          if (
            error.details &&
            error.details.error &&
            error.details.error === "emailEmpty"
          ) {
            isEmailError.value = true;
          } else {
            isError.value = true;
          }
        } finally {
          isValidating.value = false;
        }
      })();
    }
    return {
      isValidating,
      isError,
      isEmailError,
    };
  },
});
