
import { defineComponent, computed } from "vue";

import PageTitle from "@/components/common/PageTitle.vue";
import { lineAuthURL } from "@/utils/line";
import { getRedirectPath, redirectMyPageIfUser } from "@/utils/utils";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "LineLogin",
  components: {
    PageTitle,
  },
  setup() {
    const route = useRoute();
    const redirectPath = getRedirectPath(route);
    redirectMyPageIfUser();
    const lineAuthUrl = computed(() => {
      const url = lineAuthURL("/callback/line", {
        redirectPath,
        pathname: location.pathname,
      });
      return url;
    });
    return { lineAuthUrl };
  },
});
