import { liveStatus } from "@/utils/constant";
import { isEmpty, doc2data } from "@/utils/utils";
import { db, firestore } from "@/utils/firebase";

import { UAParser } from "ua-parser-js";

const uaParser = new UAParser(window.navigator.userAgent);

export const fairplayCert = async () => {
  const req = await fetch("/fairplay.cer");
  const cert = await req.arrayBuffer();
  const serverCertificate = new Uint8Array(cert);
  return serverCertificate;
};

export const getKeyType = () => {
  const browser = uaParser.getBrowser().name;
  const os = uaParser.getOS();
  if (os.name === "iOS" && (browser === "Edge" || browser === "Chrome")) {
    return "fairplay";
  }
  if (browser === "Chrome" || browser === "Firefox") {
    return "widevine";
  }
  if (browser === "Safari" || browser === "Mobile Safari") {
    return "fairplay";
  }
  if (browser === "Edge") {
    return "playready";
  }
  return null;
};

export const getPlayerConfiguration = (keyData, keyType) => {
  const streaming = {
    retryParameters: { timeout: 0 },
    bufferingGoal: 15,
    rebufferingGoal: 10,
    bufferBehind: 15,
  };
  const manifest = {
    retryParameters: { timeout: 0 },
  };
  if (keyType === "widevine") {
    return {
      drm: {
        servers: {
          "com.widevine.alpha": keyData.data.key,
        },
        advanced: {
          "com.widevine.alpha": {
            videoRobustness: "SW_SECURE_CRYPTO",
            audioRobustness: "SW_SECURE_CRYPTO",
          },
        },
      },
      streaming,
      manifest,
    };
  }
  if (keyType === "playready") {
    return {
      type: "application/x-mpegURL",
      drm: {
        servers: {
          "com.microsoft.playready": `${keyData.data.licenseUrl}?ExpressPlayToken=${keyData.data.key}`,
        },
        advanced: {
          "com.microsoft.playready.recommendation": {
            videoRobustness: "3000",
            sessionType: "temporary",
          },
        },
      },
      streaming,
      manifest,
    };
  }
  if (keyType === "fairplay") {
    return {
      drm: {
        servers: {
          "com.apple.fps.1_0": keyData.data.key,
        },
      },
      streaming,
      manifest,
    };
  }
};

export const UIConfig = {
  addSeekBar: false,
  addBigPlayButton: true,
  doubleClickForFullscreen: false,
  enableKeyboardPlaybackControls: false,
  controlPanelElements: [
    // "play_pause",
    // "time_and_duration",
    "spacer",
    "mute",
    "volume",
    //            "fullscreen",
    // "overflow_menu",
  ],
};

export const getUserRights = async (rights: { id: string }[], uid: string) => {
  if (isEmpty(rights)) {
    return [];
  }
  const rightsRef = await db
    .collection(`users/${uid}/rights`)
    .where(
      firestore.FieldPath.documentId(),
      "in",
      rights.map((r) => r.id),
    )
    .get();
  return rightsRef.docs.map(doc2data());
};

export const getPlayerStatus = (store, content, hasAdminRight) => {
  const now = store.state.date.unix();

  if (isEmpty(content.value)) {
    console.log("beforeLoad");
    return liveStatus.beforeLoad;
  }
  if (content.value.VODFlag && !content.value.VODEndAt) {
    // VOD　終了時間がない
    console.log("beforeLoad");
    return liveStatus.beforeLoad;
  }
  // 必要なデータがない
  if (
    !content.value.sellStartAt ||
    !content.value.liveStartAt ||
    !content.value.liveEndAt
  ) {
    console.log("beforeLoad");
    return liveStatus.beforeLoad;
  }
  // 販売開始前
  if (now <= content.value.sellStartAt.seconds) {
    console.log("beforeSell");
    return liveStatus.beforeSell;
  }
  // ライブ前の時間
  if (now <= content.value.liveStartAt.seconds) {
    if (hasAdminRight.value) {
      console.log("onLive as admin");
      return liveStatus.onLive;
    }
    if (!content.value.onLiveStreaming) {
      // ライブ配信していない
      console.log("beforeLive");
      return liveStatus.beforeLive;
    }
    if (content.value.onLiveStreaming) {
      // ライブ前の時間で、ライブ配信している
      console.log("onLive");
      return liveStatus.onLive;
    }
  }
  // ライブ中の時間
  if (
    content.value.liveStartAt.seconds <= now &&
    now <= content.value.liveEndAt.seconds
  ) {
    // 配信の有無関係なくライブ中
    console.log("onLive");
    return liveStatus.onLive;
  }
  // ライブ後５時間以内で、まだ配信をしている
  if (
    now <= content.value.liveEndAt.seconds + 3600 * 5 &&
    content.value.onLiveStreaming
  ) {
    console.log("onLive");
    return liveStatus.onLive;
  }
  // ここからライブ配信終了
  if (!content.value.VODFlag) {
    // VODしない場合は終了
    console.log("live ended. No DRM");
    return liveStatus.EndOfLive;
  }
  // VODの期限内
  if (now <= content.value.VODEndAt.seconds) {
    if (!content.value.isVODReady) {
      console.log("AfterLive");
      return liveStatus.AfterLive;
    } else {
      console.log("onVOD");
      return liveStatus.onVOD;
    }
  }
  console.log("end Of live");
  return liveStatus.EndOfLive;
};
