
import { defineComponent, ref } from "vue";

import HeaderNav from "@/components/common/HeaderNav.vue";
import UserButton from "@/components/common/UserButton.vue";

export default defineComponent({
  components: {
    HeaderNav,
    UserButton,
  },
  /*
    NOTE: ページ遷移の際に初期化する必要があることを考えると
          storeにnavの開閉情報をもたせるべき？
  */
  setup() {
    const navIsActive = ref(false);
    const hasVideo = ref(false);

    const openNav = () => {
      navIsActive.value = true;
    };
    const closeNav = () => {
      navIsActive.value = false;
    };
    const init = () => {
      hasVideo.value = false;
      closeNav();
    };
    return {
      navIsActive,
      hasVideo,
      openNav,
      closeNav,
      init,
    };
  },
});
