
import { defineComponent } from "vue";

export default defineComponent({
  name: "Checkbox",
  props: {
    id: String,
    label: String,
    modelValue: {
      type: [Boolean],
    },
  },
  emits: ["update:modelValue"],
  setup(props, context) {
    const update = (e) => {
      context.emit("update:modelValue", e.target.checked);
    };
    return {
      update,
    };
  },
});
