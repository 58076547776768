import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";

import ContentsList from "@/views/Contents/List.vue";
import ContentsPlayer from "@/views/Contents/Player.vue";
import ContentsSubscribe from "@/views/Contents/Subscribe.vue";
import ContentsAdmin from "@/views/Contents/Admin.vue";
import AccountIndex from "@/views/Account/Index.vue";
import AccountSignup from "@/views/Account/Signup.vue";
import AccountSignin from "@/views/Account/Signin.vue";
import AccountLineLogin from "@/views/Account/LineLogin.vue";
import AccountLineCallback from "@/views/Account/LineCallback.vue";

import AccountChangeEmail from "@/views/Account/ChangeEmail.vue";
import AccountError from "@/views/Account/Error.vue";
import AccountMypage from "@/views/Account/Mypage.vue";
import AccountHistory from "@/views/Account/History.vue";
import AccountTickets from "@/views/Account/Tickets.vue";
import AccountResetPasswd from "@/views/Account/ResetPasswd.vue";
import AccountAction from "@/views/Account/Action.vue";

import AdminLayout from "@/components/AdminLayout.vue";

import DocsAsks from "@/views/Docs/Asks.vue";
import DocsThankyou from "@/views/Docs/Thankyou.vue";
import DocsTerms from "@/views/Docs/Terms.vue";
import DocsPrivacy from "@/views/Docs/Privacy.vue";
import DocsFaq from "@/views/Docs/Faq.vue";
import DocsLaw from "@/views/Docs/Law.vue";
import DocsRequirements from "@/views/Docs/Requirements.vue";
import DocsCopyright from "@/views/Docs/Copyright.vue";
import Docs404 from "@/views/Docs/404.vue";

const adminChildren: Array<RouteRecordRaw> = [];

const routes: Array<any> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/live/lists/:category?/:subcategory?/:subsubcategory?",
    name: "ContentsList",
    component: ContentsList,
  },
  {
    path: "/live/:livehousename/:date/:contentId",
    name: "ContentsPlayer",
    component: ContentsPlayer,
  },
  {
    path: "/live/:livehousename/:date/:contentId/subscribe",
    name: "ContentsSubscribe",
    component: ContentsSubscribe,
  },
  {
    path: "/live/:livehousename/:date/:contentId/admin",
    name: "ContentsAdmin",
    component: ContentsAdmin,
  },
  {
    path: "/account",
    name: "AccountIndex",
    component: AccountIndex,
  },
  {
    path: "/account/line",
    name: "AccountLineLogin",
    component: AccountLineLogin,
  },
  {
    path: "/account/signup",
    name: "AccountSignup",
    component: AccountSignup,
  },
  {
    path: "/account/signin",
    name: "AccountSignin",
    component: AccountSignin,
  },
  {
    path: "/callback/line",
    name: "AccountLineCallback",
    component: AccountLineCallback,
  },
  {
    path: "/account/changeemail",
    name: "AccountChangeEmail",
    component: AccountChangeEmail,
  },
  {
    path: "/account/error",
    name: "AccountError",
    component: AccountError,
  },
  {
    path: "/account/mypage",
    name: "AccountMypage",
    component: AccountMypage,
  },
  {
    path: "/account/history",
    name: "AccountHistory",
    component: AccountHistory,
  },
  {
    path: "/account/tickets",
    name: "AccountTickets",
    component: AccountTickets,
  },
  {
    path: "/account/resetpasswd",
    name: "AccountResetPasswd",
    component: AccountResetPasswd,
  },
  {
    path: "/account/action",
    name: "AccountAction",
    component: AccountAction,
  },
  {
    path: "/admin",
    component: AdminLayout,
    children: adminChildren,
  },
  {
    path: "/docs/asks",
    name: "DocsAsks",
    component: DocsAsks,
  },
  {
    path: "/docs/thankyou",
    name: "DocsThankyou",
    component: DocsThankyou,
  },
  {
    path: "/docs/terms",
    name: "DocsTerms",
    component: DocsTerms,
  },
  {
    path: "/docs/privacy",
    name: "DocsPrivacy",
    component: DocsPrivacy,
  },
  {
    path: "/docs/faq",
    name: "DocsFaq",
    component: DocsFaq,
  },
  {
    path: "/docs/law",
    name: "DocsLaw",
    component: DocsLaw,
  },
  {
    path: "/docs/requirements",
    name: "DocsRequirements",
    component: DocsRequirements,
  },
  {
    path: "/docs/copyright",
    name: "DocsCopyright",
    component: DocsCopyright,
  },
  {
    path: "/docs/404",
    name: "Docs404",
    component: Docs404,
  },
  {
    path: "/:page(.*)",
    name: "Docs404",
    component: Docs404,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
