
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";

import { db } from "@/utils/firebase9";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  documentId,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

import {
  deleteSourceFunctions,
  SubscribeContentsWithTokenFunctions,
} from "@/utils/firebase/functions";
import { stripePublicKey } from "@/config/project";
import CheckboxComponent from "@/components/common/Checkbox.vue";
import FormComponent from "@/components/common/Form.vue";
import FormItem from "@/components/common/FormItem.vue";
import NotFound from "@/components/NotFound.vue";
import RegisteredCreditCard from "@/components/RegisteredCreditCard.vue";

import Term from "@/views/terms/202207.vue";

import {
  requireSignin,
  useUid,
  useUser,
  useContentId,
  contentUrlPrefix,
  doc2data,
} from "@/utils/utils";

import { loadStripe } from "@stripe/stripe-js";

export default defineComponent({
  // TODO Test
  //   call delete if no card data
  //   subscribeExistCard if no card data
  //   invalid card info
  // TODO
  //   lock input and disable button if submit: done
  //   loading view.
  //   error case

  name: "ContentsSubscribe",
  components: {
    NotFound,
    CheckboxComponent,
    FormComponent,
    FormItem,
    RegisteredCreditCard,

    Term,
  },
  async setup() {
    const router = useRouter();
    const user = useUser();
    const uid = useUid();
    const contentId = useContentId();

    const cardErrors = ref({});
    const source = ref();
    const isSubmitting = ref();
    const saveCard = ref(true);
    const loading = ref(false);
    const ticket = ref();

    const pageLoadSuccess = ref(false);

    const shareEmail = ref("yes");

    const notFoundResponse = {
      redirect: false,
      notFound: true,
      pageLoading: false,
    };

    if (requireSignin(user.value, router, location)) {
      return {
        redirect: false,
        notFound: false,
        pageLoading: true,
      };
    }

    const cardNumberEvent = (key) => {
      return (event) => {
        const newError = { ...cardErrors.value };
        if (event.error) {
          newError[key] = true;
        } else {
          newError[key] = false;
        }
        cardErrors.value = newError;
      };
    };
    let stripe = null;
    let cardNumber = null;
    let cardExpiry = null;
    let cardCvc = null;

    onMounted(() => {
      if (pageLoadSuccess.value) {
        const elements = stripe.elements();
        cardNumber = elements.create("cardNumber", {
          style: {
            base: {},
          },
        });
        cardNumber.mount("#card-number");
        cardExpiry = elements.create("cardExpiry");
        cardExpiry.mount("#card-expiry");
        cardCvc = elements.create("cardCvc");
        cardCvc.mount("#card-cvc");

        cardNumber.on("change", cardNumberEvent("number"));
        cardExpiry.on("change", cardNumberEvent("expiry"));
        cardCvc.on("change", cardNumberEvent("cvs"));
      }
    });

    stripe = await loadStripe(stripePublicKey);

    const contentDoc = await getDoc(doc(db, `contents/${contentId.value}`));
    if (!contentDoc.exists()) {
      console.log("no contents");
      return notFoundResponse;
    }
    const content = ref(doc2data()(contentDoc));
    // check contents
    if (
      content.value.PublishEndAt &&
      content.value.PublishEndAt.toDate().getTime() < new Date().getTime()
    ) {
      console.log("end");
      return notFoundResponse;
    }
    const now = moment().unix();
    if (
      !content.value.sellStartAt ||
      now <= content.value.sellStartAt.seconds
    ) {
      console.log("before cell");
      return notFoundResponse;
    }

    // end of contents

    const contentsRights = content.value.rights;

    const rightsRef = await getDocs(
      query(
        collection(db, "rights"),
        where(
          documentId(),
          "in",
          contentsRights.map((r) => r.id),
        ),
      ),
    );

    const rightsDataSet = rightsRef.docs
      .map(doc2data())
      .filter((data) => data.type !== 3);
    if (rightsDataSet.length === 0) {
      console.log("no rights");
      return notFoundResponse;
    }
    const rightsData = rightsDataSet[0];

    const right = await getDocs(
      query(
        collection(db, `users/${uid.value}/rights`),
        where(
          documentId(),
          "in",
          rightsDataSet.map((r) => r.id),
        ),
      ),
    );

    if (right.docs.length > 0) {
      router.push(contentUrlPrefix(content.value));
      return {
        redirect: true,
        notFound: false,
        pageLoading: false,
      };
    }
    if (rightsData.type === 4) {
      const ticketDoc = await getDoc(doc(db, `tickets/${rightsData.ticketId}`));
      if (!ticketDoc.exists()) {
        return notFoundResponse;
      }
      ticket.value = ticketDoc.data();
    }

    const existPayment = await getDoc(
      doc(db, `users/${uid.value}/stripePublic/payment`),
    );

    if (existPayment.exists()) {
      source.value = existPayment.data().source;
    }

    const hasError = computed(() => {
      return Object.keys(cardErrors.value).reduce((tmp, key) => {
        if (cardErrors.value[key]) {
          return true;
        }
        return tmp;
      }, false);
    });

    const deleteSource = async () => {
      loading.value = true;
      try {
        await deleteSourceFunctions();
      } catch (e) {
        console.log(e);
      }
      loading.value = false;
      source.value = null;
    };

    const saveEmailConfirm = async () => {
      const data = {
        uid: uid.value,
        createdAt: serverTimestamp(),
      };
      await setDoc(doc(db, `evens/202207/emailconfirm/${uid.value}`), data);
    };
    const doSubscript = async (option) => {
      isSubmitting.value = true;
      loading.value = true;

      if (shareEmail.value == "yes") {
        await saveEmailConfirm();
      }

      const postData = Object.assign(
        {
          rightsId: rightsData.id,
          debug: 1,
        },
        option,
      );
      try {
        const { data } = await SubscribeContentsWithTokenFunctions(postData);

        if (data && data.result) {
          // ok
          router.push({
            path: contentUrlPrefix(content.value),
          });
        } else {
          // TODO:
          alert("ごめんなさい。エラーです。問い合わせてください");
        }
      } catch (e) {
        // TODO
        alert("ごめんなさい。エラーです。問い合わせてください");
      }
      loading.value = false;
      isSubmitting.value = false;
    };

    const subscribeExistCard = async () => {
      const postData = {
        reuse: 1,
      };
      return await doSubscript(postData);
    };
    const subscribe = async () => {
      const result = await stripe.createToken(cardNumber);

      const postData = {
        sourceId: result.token.id,
        save: saveCard.value,
      };
      return await doSubscript(postData);
    };
    pageLoadSuccess.value = true;

    return {
      redirect: false,
      content,
      rightsData,
      rightsDataSet,
      stripe,
      source,
      saveCard,
      notFound: false,
      pageLoading: false,
      isSubmitting,
      cardErrors,
      hasError,

      subscribeExistCard,
      subscribe,
      deleteSource,

      loading,

      ticket,

      moment,

      shareEmail,
    };
  },
});
