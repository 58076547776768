
import { defineComponent, watch, computed, ref } from "vue";

import { useRouter } from "vue-router";

import { db } from "@/utils/firebase9";
import { doc, setDoc } from "firebase/firestore";
import { sendEmailVerification } from "firebase/auth";
import PageTitle from "@/components/common/PageTitle.vue";

import Modal from "@/components/common/Modal.vue";
import {
  requireSignin,
  useUser,
  useUserPath,
  useDbUser,
  useUserEmail,
  isNull,
  topPage,
} from "@/utils/utils";

export default defineComponent({
  components: {
    PageTitle,
    Modal,
  },
  setup() {
    const router = useRouter();
    const userPath = useUserPath();
    const user = useUser();
    const dbuser = useDbUser();
    const userEmail = useUserEmail();

    const userData = ref<{ [key: string]: string }>({});
    const nicknameMessage = ref("");
    const nicknameEmpty = ref(false);
    const modalOpen = ref(false);
    const sendedEmailVerification = ref(false);

    requireSignin(user.value, router, location);

    watch(dbuser, () => {
      if (dbuser.value) {
        userData.value = dbuser.value;
      }
    });
    watch(userData, () => {
      if (isNull((userData.value || {}).nickname)) {
        nicknameEmpty.value = true;
        nicknameMessage.value = "mypage.setNickname";
      } else {
        nicknameEmpty.value = false;
      }
    });

    const providers = computed(() => {
      return user.value.providerData;
    });

    const submit = async () => {
      const { nickname } = userData.value;
      if (nickname === "") {
        nicknameEmpty.value = true;
        nicknameMessage.value = "mypage.setNickname";
      } else {
        const updateData = { nickname };
        await setDoc(doc(db, userPath.value), updateData, { merge: true });
        nicknameMessage.value = "mypage.updatedNickname";
      }
    };
    const doSendEmailVerification = async () => {
      try {
        console.log(user.value)
        await sendEmailVerification(user.value);
        sendedEmailVerification.value = true;
      } catch (e) {
        console.log(e)
        modalOpen.value = true;
      }
    };
    return {
      modalOpen,
      nicknameMessage,
      nicknameEmpty,
      sendedEmailVerification,

      user,
      dbuser,
      userData,
      userEmail,

      providers,
      submit,
      doSendEmailVerification,

      topPage,
    };
  },
});
