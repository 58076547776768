
import { defineComponent, onMounted, onUnmounted, ref, computed } from "vue";
import { useContentId, useUid, doc2data, timeFormat } from "@/utils/utils";

import { db, firestore } from "@/utils/firebase";

export default defineComponent({
  async setup() {
    const contentId = useContentId();
    const uid = useUid();
    const ngwords = ref("");
    const messagesRaw = ref([]);
    const messages = ref([]);
    const chatBadUsers = ref([]);

    let messageDetacher = null;
    let chatBadUsersDetacher = null;
    onMounted(() => {
      messageDetacher = db
        .collection(`contents/${contentId.value}/messages`)
        .orderBy("at", "desc")
        .limit(200)
        .onSnapshot((result) => {
          messages.value = result.docs.map(doc2data()).reverse();
          messagesRaw.value = result.docs;
        });
    });
    onUnmounted(() => {
      if (messageDetacher) {
        messageDetacher();
      }
      if (chatBadUsersDetacher) {
        chatBadUsersDetacher();
      }
    });

    const contentDoc = await db.doc(`contents/${contentId.value}`).get();
    if (!contentDoc.exists) {
      return { notFound: true };
    }
    const myadmin = await db
      .doc(`contents/${contentId.value}/admins/${uid.value}`)
      .get();
    if (!myadmin.exists) {
      return { notFound: true };
    }

    const content = ref(contentDoc.data());
    const onLiveStreaming = ref(content.value.onLiveStreaming);
    const inList = ref(content.value.inList);

    const admins = ref(
      (
        await db.collection(`contents/${contentId.value}/admins`).get()
      ).docs.map(doc2data()),
    );
    const ngwordsDoc = await db
      .doc(`contents/${contentId.value}/ngwords/data`)
      .get();
    if (ngwordsDoc.exists) {
      const data = ngwordsDoc.data();
      ngwords.value = data.data;
    }

    const updateData = async () => {
      await db
        .doc(`contents/${contentId.value}/ngwords/data`)
        .set({ data: ngwords.value || "" }, { merge: true });
    };
    const messageName = (message) => {
      return message.nickname || "ななし(" + message.uid.slice(0, 4) + ")";
    };
    const addBadUser = async (message) => {
      const uid = message.uid;

      if (confirm(messageName(message) + "を禁止ユーザリストに追加します")) {
        messagesRaw.value.forEach((m) => {
          if (m.data().uid === uid) {
            m.ref.update("deletedFlag", true);
          }
        });
        await db.doc(`contents/${contentId.value}/chatBadUser/${uid}`).set(
          {
            uid,
            nickname: messageName(message),
            byUid: uid.value,
            created: firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        );
      }
    };
    const ngwordsPreview = computed(() => {
      return (ngwords.value || "").split(/,|\n/).filter((a) => a.length > 0);
    });

    const deleteMessage = async (message) => {
      if (confirm("ok" + message.id)) {
        const path = `contents/${contentId.value}/messages/${message.id}`;
        await db.doc(path).update("deletedFlag", true);
      }
    };
    const badUserMessage = (uid) => {
      return chatBadUsers.value.find((a) => a.id === uid);
    };
    const reset = () => {
      if (confirm("全てリセットして、書き込みも復活します。デバック用")) {
        // reset bad user
        chatBadUsers.value.forEach((user) => {
          user.ref.delete();
        });

        // reset ngword
        ngwords.value = "";
        updateData();

        // rollback message
        messagesRaw.value.forEach((m) => {
          m.ref.update("deletedFlag", false);
        });
      }
    };
    const streaming = (status) => {
      onLiveStreaming.value = status;
      db.doc(`contents/${contentId.value}`).update("onLiveStreaming", status);
    };
    // const inList = ref(content.value.inList);
    const updateList = (status) => {
      inList.value = status;
      db.doc(`contents/${contentId.value}`).update("inList", status);
    };
    const updateTitle = () => {
      db.doc(`contents/${contentId.value}`).update("name", content.value.name);
    };

    chatBadUsersDetacher = await db
      .collection(`contents/${contentId.value}/chatBadUser`)
      .onSnapshot((tmp) => {
        chatBadUsers.value = tmp.docs.map((chatBadUser) => {
          const cdata = chatBadUser.data();
          cdata.id = chatBadUser.id;
          cdata.ref = chatBadUser.ref;
          return cdata;
        });
      });
    return {
      content,
      admins,
      ngwordsPreview,
      ngwords,
      notFound: false,
      chatBadUsers,
      messages,

      badUserMessage,
      messageName,
      timeFormat,
      addBadUser,
      deleteMessage,
      reset,

      onLiveStreaming,
      streaming,

      inList,
      updateList,
      updateTitle,
    };
  },
});
