
import { defineComponent, onUnmounted, ref, computed, watch } from "vue";
import PageTitle from "@/components/common/PageTitle.vue";
import { db } from "@/utils/firebase9";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  documentId,
} from "firebase/firestore";
import { useUid, doc2data, chunk, contentUrlPrefix } from "@/utils/utils";

import moment from "moment";

export default defineComponent({
  name: "AccountTicket",
  components: {
    PageTitle,
  },
  async setup() {
    const userTickets = ref([]);
    const userTicketDetacher = ref();

    const ticketObj = ref({});
    const contentsObj = ref({});

    const uid = useUid();
    const path = computed(() => {
      return `users/${uid.value}/tickets`;
    });

    const loadUserTicket = () => {
      if (path.value) {
        if (userTicketDetacher.value) {
          userTicketDetacher.value();
        }
        userTicketDetacher.value = onSnapshot(
          query(
            collection(db, path.value),
            orderBy("createdAt", "asc"),
            limit(100),
          ),
          (result) => {
            userTickets.value = result.docs.map(doc2data()).reverse();
          },
        );
      }
    };
    watch(path, () => {
      loadUserTicket();
    });
    watch(userTickets, () => {
      chunk(
        Array.from(new Set(userTickets.value.map((t) => t.ticketId))),
        10,
      ).map(async (ticketIds) => {
        const docs = await getDocs(
          query(
            collection(db, "tickets"),
            where(documentId(), "in", ticketIds),
          ),
        );
        docs.docs.map((doc) => {
          const data = doc.data();
          ticketObj.value[doc.id] = data;
        });
      });
      chunk(
        Array.from(
          new Set(
            userTickets.value
              .map((t) => t.history)
              .reduce((tmp, histories) => {
                return tmp.concat(histories);
              }, [])
              .map((a) => a.contentId),
          ),
        ),
        10,
      ).map(async (contentIds) => {
        const docs = await getDocs(
          query(
            collection(db, "contents"),
            where(documentId(), "in", contentIds),
          ),
        );
        docs.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          contentsObj.value[doc.id] = data;
        });
      });
    });
    loadUserTicket();
    onUnmounted(() => {
      if (userTicketDetacher.value) {
        userTicketDetacher.value();
      }
    });
    return {
      userTickets,

      moment,
      contentUrlPrefix,

      ticketObj,
      contentsObj,
    };
  },
});
