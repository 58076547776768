
import { defineComponent, computed, ref } from "vue";

import { useHead } from "@vueuse/head";
import { useRouter } from "vue-router";

import isEmail from "validator/lib/isEmail";
import { emailFunctions } from "@/utils/firebase/functions";
import PageTitle from "@/components/common/PageTitle.vue";

import { useUid } from "@/utils/utils";

export default defineComponent({
  components: {
    PageTitle,
  },
  setup() {
    const formValues = ref<{ [key: string]: string }>({});
    const submitting = ref(false);

    const uid = useUid();
    const router = useRouter();

    useHead({
      title: "お問い合わせ  - LiveMedia Space",
    });
    const asksCategory = [
      {
        name: "1.会員登録・ログインについて",
      },
      {
        name: "2.動画内容について",
      },
      {
        name: "3.メールについて",
      },
      {
        name: "4.その他",
      },
    ];
    const userAgent = navigator.userAgent;

    const submit = async () => {
      submitting.value = true;
      try {
        const body = {
          email: formValues.value.email,
          category: asksCategory[formValues.value.category].name,
          message: [
            "「" + formValues.value.message + "」",
            userAgent,
            uid.value || "",
          ].join("\n\n"),
        };
        // console.log(body);
        await emailFunctions(body);
        router.push("/docs/thankyou");
      } catch (e) {
        console.log(e);
      }
      submitting.value = false;
    };
    const errors = computed(() => {
      const _errors: { [key: string]: string[] } = {};
      if (!isEmail(formValues.value.email || "")) {
        _errors.email = ["account.error.email.invalid"];
      }
      if (!asksCategory[formValues.value.category]) {
        _errors.category = ["asks.error.category.empty"];
      }
      if (String(formValues.value.message).length < 1) {
        _errors.message = ["asks.error.messageempty"];
      }
      return _errors;
    });
    const hasError = computed(() => {
      return Object.keys(errors.value).length > 0;
    });

    return {
      errors,
      submitting,
      formValues,
      asksCategory,

      submit,
      hasError,
    };
  },
});
