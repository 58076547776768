
import { defineComponent, onUnmounted, ref, computed, watch } from "vue";
import PageTitle from "@/components/common/PageTitle.vue";
import { db } from "@/utils/firebase9";
import {
  orderBy,
  collection,
  query,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { useUid, doc2data } from "@/utils/utils";

import moment from "moment";

export default defineComponent({
  name: "AccountHistory",
  components: {
    PageTitle,
  },
  async setup() {
    const histories = ref([]);
    const historyDetacher = ref();
    const uid = useUid();
    const path = computed(() => {
      return `users/${uid.value}/histories`;
    });

    const loadHistory = () => {
      if (path.value) {
        if (historyDetacher.value) {
          historyDetacher.value();
        }
        historyDetacher.value = onSnapshot(
          query(
            collection(db, path.value),
            orderBy("createdAt", "asc"),
            limit(100),
          ),
          (result) => {
            histories.value = result.docs.map(doc2data()).reverse();
          },
        );
      }
    };
    watch(path, () => {
      loadHistory();
    });
    loadHistory();
    onUnmounted(() => {
      if (historyDetacher.value) {
        historyDetacher.value();
      }
    });
    return {
      histories,
      moment,
    };
  },
});
