import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed48dd10"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: _normalizeClass(["ShareButton", { 'is-landscape': !this.$store.state.orientation.isPortrait }]),
    href: _ctx.url,
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
    target: "_blank",
    rel: "noopener noreferrer"
  }, [
    _createElementVNode("i", {
      class: _normalizeClass('fab fa-' + _ctx.service)
    }, null, 2)
  ], 10, _hoisted_1))
}