
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormItem",
  props: {
    label: String,
    required: Boolean,
  },
});
