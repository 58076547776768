import { createApp } from "vue";

import { createHead } from "@vueuse/head";
import VueGtag from "vue-gtag";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import Oruga from "@oruga-ui/oruga-next";
import "@oruga-ui/oruga-next/dist/oruga-full.css";

import Loading from "@/components/common/Loading.vue";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import { sentry } from "@/config/project";

require("@/assets/sass/main.sass");

const app = createApp(App);
const head = createHead();
app.use(store);
app.use(router);
app.use(i18n);
app.use(Oruga);

app.use(head);

app.component("Loading", Loading);

app.use(VueGtag, {
  config: { id: "UA-179991812-1" },
});

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    app,
    dsn: sentry.dsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost"],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
app.mount("#app");
