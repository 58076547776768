
import { defineComponent, computed, ref } from "vue";

import { useRouter } from "vue-router";

import isEmail from "validator/lib/isEmail";
import { db, auth, firestore } from "@/utils/firebase";
import PageTitle from "@/components/common/PageTitle.vue";

import { topPage, redirectMyPageIfUser } from "@/utils/utils";

export default defineComponent({
  name: "Signup",
  components: {
    PageTitle,
  },

  setup() {
    const email = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const emailTaken = ref("---invalid---");

    const router = useRouter();
    redirectMyPageIfUser();

    const errors = computed(() => {
      const _errors: { [key: string]: string[] } = {};
      if (password.value !== confirmPassword.value) {
        _errors.confirm = ["account.error.password.mismatch"];
      }
      if (password.value.length < 8) {
        _errors.password = ["account.error.newPassword.tooshort"];
      }
      if (password.value !== null && !password.value.match(/^[A-Za-z0-9]*$/)) {
        _errors.password = ["account.error.password.validCharactor"];
      }
      if (!isEmail(email.value)) {
        _errors.email = ["account.error.email.invalid"];
      } else if (email.value === emailTaken.value) {
        _errors.email = ["account.error.email.taken"];
      }
      return _errors;
    });

    const onSignup = async () => {
      try {
        const result = await auth.createUserWithEmailAndPassword(
          email.value,
          password.value,
        );
        console.log("signup success", result.user.uid);
        await db.doc(`users/${result.user.uid}`).set({
          uid: result.user.uid,
          created: firestore.FieldValue.serverTimestamp(),
        });
        await result.user.sendEmailVerification();
        router.push(topPage);
      } catch (error) {
        console.log("onSignup failed", error.code, error.message);
        if (error.code === "auth/email-already-in-use") {
          emailTaken.value = email.value;
        } else {
          // BUGBUG: Not processing other type of errors
        }
      }
    };
    return {
      email,
      password,
      confirmPassword,
      emailTaken,

      errors,

      onSignup,
    };
  },
});
