import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa3f4e50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "SignUp" }
const _hoisted_2 = { class: "Layout Layout--m48" }
const _hoisted_3 = { class: "Layout__item" }
const _hoisted_4 = { class: "Layout__item" }
const _hoisted_5 = { class: "Layout__item" }
const _hoisted_6 = { class: "SignUp__links" }
const _hoisted_7 = { class: "Layout Layout--m24" }
const _hoisted_8 = { class: "Layout__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_title = _resolveComponent("page-title")!
  const _component_o_input = _resolveComponent("o-input")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_o_button = _resolveComponent("o-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSignup && _ctx.onSignup(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_page_title, {
            title: _ctx.$t('account.registration')
          }, null, 8, ["title"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_o_field, {
            variant: _ctx.errors.email ? 'danger' : 'success',
            message: _ctx.errors.email && _ctx.$t(_ctx.errors.email[0]),
            label: _ctx.$t('account.email')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_o_input, {
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                type: "email",
                placeholder: _ctx.$t('account.emailPlaceHolder'),
                maxlength: "256"
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["variant", "message", "label"]),
          _createVNode(_component_o_field, {
            variant: _ctx.errors.password ? 'danger' : 'success',
            message: _ctx.errors.password && _ctx.$t(_ctx.errors.password[0]),
            label: _ctx.$t('account.password')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_o_input, {
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                type: "password",
                placeholder: _ctx.$t('account.passwordPlaceHolder'),
                maxlength: "30",
                "password-reveal": ""
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["variant", "message", "label"]),
          _createVNode(_component_o_field, {
            variant: _ctx.errors.confirm ? 'danger' : 'success',
            message: _ctx.errors.confirm && _ctx.$t(_ctx.errors.confirm[0]),
            label: _ctx.$t('account.confirmPassword')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_o_input, {
                modelValue: _ctx.confirmPassword,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmPassword) = $event)),
                type: "password",
                placeholder: _ctx.$t('account.confirmPasswordPlaceHolder'),
                maxlength: "30",
                "password-reveal": ""
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["variant", "message", "label"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_o_button, {
            class: "Button Button--large",
            type: "is-primary",
            disabled: Object.keys(_ctx.errors).length > 0,
            onClick: _ctx.onSignup
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("button.next")), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ])
    ], 32),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_router_link, {
            class: "SignUp__link",
            to: "/account/signin"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("account.pleaseSignIn")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}