
import { defineComponent, ref } from "vue";

import PageTitle from "@/components/common/PageTitle.vue";
import { auth } from "@/utils/firebase";

export default defineComponent({
  name: "ResetPassword",
  components: {
    PageTitle,
  },
  setup() {
    const email = ref("");
    const submitting = ref(false);
    const isSended = ref(false);
    const errors = ref({});

    const resetPassword = async () => {
      submitting.value = true;
      errors.value = {};
      try {
        await auth.sendPasswordResetEmail(email.value);
        isSended.value = true;
      } catch (e) {
        console.log(e);
        const errorCode = "account.error.code." + e.code;
        errors.value = { email: [errorCode] };
      }
      submitting.value = false;
    };
    return {
      email,
      resetPassword,
      submitting,
      isSended,
      errors,
    };
  },
});
