
import muxjs from "mux.js";
import moment from "moment";

// import shaka from 'shaka-player/dist/shaka-player.compiled.debug.js';
// import shaka from "shaka-player";
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  computed,
  watch,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

// import shaka from "shaka-player/dist/shaka-player.ui";
// import shaka from "shaka-player/dist/shaka-player.ui.debug";
import "shaka-player/dist/controls.css";

import { db } from "@/utils/firebase9";

import { doc, onSnapshot } from "firebase/firestore";

import {
  getContentsKeyFunctions,
  confirmRestrectEmailFunctions,
  getDownloadUrlFunctions,
} from "@/utils/firebase/functions";

import { liveStatus } from "@/utils/constant";

import { DocumentData } from "firebase/firestore";

import NotFound from "@/components/NotFound.vue";
import NotSubscribe from "@/components/NotSubscribe.vue";
import NotSupportedBrowser from "@/components/NotSupportedBrowser.vue";
import PlayerChat from "@/views/Contents/Player/Chat.vue";
import PlayerInformation from "@/views/Contents/Player/Information.vue";

import {
  useContentId,
  sleep,
  isEmpty,
  useUid,
  useUser,
  useAuthenticated,
  requireSignin,
} from "@/utils/utils";

import {
  getPlayerStatus,
  getPlayerConfiguration,
  fairplayCert,
  getUserRights,
  getKeyType,
  UIConfig,
} from "@/views/Contents/Player/PlayerUtils";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const shaka = require("shaka-player/dist/shaka-player.ui");

export default defineComponent({
  name: "Pleyer",
  components: {
    NotFound,
    NotSubscribe,
    NotSupportedBrowser,
    PlayerChat,
    PlayerInformation,
  },
  emits: ["videomount"],
  setup(props, context) {
    const store = useStore();
    const router = useRouter();

    window.muxjs = muxjs;

    const detacher = ref();

    const contentId = useContentId();
    const notFound = ref(null);

    const supportedBrowser = ref(true);
    const isRestrictContents = ref(false);

    let player = null;
    const content = ref<DocumentData>({});

    //  for2023
    const download = ref<DocumentData | null>(null);

    const userRights = ref([]);
    const isReadyVideo = ref(false);

    const videoPlayerRef = ref();
    const containerRef = ref();

    const uid = useUid();
    const user = useUser();
    const authenticated = useAuthenticated();

    const mode = ref(null); // chat | info

    const subscribed = computed(() => {
      return userRights.value.length > 0;
    });

    supportedBrowser.value = shaka.Player.isBrowserSupported();

    const isAvailableContents = computed(() => {
      if (notFound.value === null) {
        return null;
      }
      if (isRestrictContents.value) {
        return false;
      }
      return (
        notFound.value === false &&
        (content.value.isTestPlayContents ||
          content.value.isFreeService ||
          subscribed.value)
      );
    });

    const hasAdminRight = computed(() => {
      return userRights.value.some((r) => r.id.startsWith("adm_"));
    });
    const withChat = computed(() => {
      return content.value.withChat || false;
    });

    const title = computed(() => {
      if (content.value.name) {
        return content.value.name + " - LiveMedia Space";
      } else {
        return "LiveMedia Space";
      }
    });
    useHead({
      title,
    });

    const keyType = getKeyType();
    const status = computed(() => {
      return getPlayerStatus(store, content, hasAdminRight);
    });

    // liveStatus.onLive ライブ配信中
    // liveStatus.onVOD VOD配信中
    const canPlay = computed(() => {
      return (
        status.value === liveStatus.onLive || status.value === liveStatus.onVOD
      );
    });

    const manifestUri = computed(() => {
      const sources = (() => {
        if (status.value === liveStatus.onLive) {
          return content.value?.livesource;
        } else if (status.value === liveStatus.onVOD) {
          return content.value?.vodsource;
        }
        return {};
      })();
      return sources[keyType];
    });

    const streamType = computed(() => {
      if (status.value === liveStatus.onLive) {
        return "LIVE";
      } else if (status.value === liveStatus.onVOD) {
        return "VOD";
      }
      return null;
    });

    // for template
    const sellEndAt = computed(() => {
      if (!isEmpty(content.value)) {
        if (content.value.VODFlag) {
          // VODも。
          return moment.unix(content.value.VODEndAt.seconds - 3600 * 24); // １日前
        } else {
          // liveのみ
          return moment.unix(content.value.liveEndAt.seconds - 3600); // １時間前
        }
      }
      return null;
    });

    const loadContents = () => {
      if (detacher.value) {
        detacher.value();
      }
      detacher.value = onSnapshot(
        doc(db, `contents/${contentId.value}`),
        async (contentDoc) => {
          if (!contentDoc.exists) {
            notFound.value = true;
            return;
          }
          content.value = contentDoc.data();
          if (
            content.value.PublishEndAt &&
            content.value.PublishEndAt.toDate().getTime() < new Date().getTime()
          ) {
            notFound.value = true;
            return;
          }

          content.value.id = contentDoc.id;

          // for 2023
          if (content.value.hasDownload) {
            getDownloadUrlFunctions({ contentId: contentId.value }).then(
              (d) => {
                if (d && d.data.result) {
                  download.value = d.data.data;
                }
              },
            );
          }

          if (content.value.isRestrictEmail) {
            try {
              const isOK = await confirmRestrectEmailFunctions({
                contentId: contentId.value,
              });
              if (!isOK || !isOK.data.result) {
                isRestrictContents.value = true;
                console.log("restrist contents");
              }
            } catch (e) {
              alert("ごめんなさい、エラーが発生しています");
              notFound.value = true;
              return;
            }
          }

          if (
            !content.value.isTestPlayContents &&
            content.value.isFreeService &&
            canPlay.value
          ) {
            requireSignin(user.value, router, location);
          }

          if (authenticated.value) {
            userRights.value = await getUserRights(
              content.value?.rights,
              uid.value,
            );
          }
          notFound.value = false;
        },
      );
    };

    const initVideo = () => {
      if (player === null || player === undefined) {
        shaka.polyfill.installAll();
        player = new shaka.Player();
        player.addEventListener("error", (e) => {
          console.log(e);
        });
        const ui = new shaka.ui.Overlay(
          player,
          containerRef.value,
          videoPlayerRef.value,
        );

        if (
          content.value.showPlayerMenu !== undefined &&
          !content.value.showPlayerMenu
        ) {
          if (!content.value.showPlayerMenu) {
            ui.configure(UIConfig);
          }
        }
      }
    };

    const play = async () => {
      console.log("PLAY");

      try {
        if (!supportedBrowser.value) {
          console.log("not supported browser");
          return;
        }
        if (content.value.drmType === "NoDRM") {
          // await player.load("/stream/dep/index.m3u8");
          await player.load(content.value.src);
          videoPlayerRef.value.play(); // TODO: shaka event??
        } else {
          if (!manifestUri.value) {
            console.log("no manifestUri");
            return;
          }
          const keyData = await getContentsKeyFunctions({
            contentId: contentId.value,
            keyType,
            streamType: streamType.value,
          });
          const playerConfiguration = getPlayerConfiguration(keyData, keyType);

          player.configure(playerConfiguration);
          if (keyType === "fairplay") {
            const cert = await fairplayCert();
            player.configure(
              "drm.advanced.com\\.apple\\.fps\\.1_0.serverCertificate",
              cert,
            );
            // https://shaka-player-demo.appspot.com/docs/api/tutorial-fairplay.html
            player
              .getNetworkingEngine()
              .registerRequestFilter((type, request) => {
                if (type != shaka.net.NetworkingEngine.RequestType.LICENSE) {
                  return;
                }
                request.headers["Content-Type"] = "application/octet-stream";
              });
          }
          await player.attach(videoPlayerRef.value);

          await player.load(manifestUri.value);
          videoPlayerRef.value.play();
        }
      } catch (e) {
        console.log("error:" + e);
        alert(
          "ごめんなさい。エラーが発生しています。しばらくして再度接続を試してください。",
        );
      }

      // NOTE: videoのロード成否にかかわらず、Headerの表示制御のためvideomountイベントを投げる
      context.emit("videomount");
    };

    watchEffect(async () => {
      if (isAvailableContents.value) {
        await sleep(300); // wait for loaded video tag.
        // can Play
        initVideo();

        isReadyVideo.value = true;
        if (canPlay.value) {
          console.log("INIT");
          play();
        }
      }
    });
    watch(status, () => {
      console.log("CALLED STATUS");
      // in loadContents, isReadyVideo is false because it wait 300ms.
      if (canPlay.value && isReadyVideo.value) {
        play();
      }
    });
    const updateMode = (_mode: string) => {
      mode.value = _mode;
    };
    watch(contentId, () => {
      content.value = {};
      notFound.value = null;
      isReadyVideo.value = false;
      isRestrictContents.value = false;
      download.value = null;
      userRights.value = [];
      loadContents();
    });

    onMounted(() => {
      loadContents();
      mode.value = withChat.value ? "chat" : "info";
    });
    onUnmounted(() => {
      if (player) {
        player.destroy();
      }
      if (detacher.value) {
        detacher.value();
      }
    });
    return {
      notFound,
      supportedBrowser,
      isRestrictContents,
      content,
      userRights,
      canPlay,
      status,

      isAvailableContents,
      mode,

      sellEndAt,

      withChat,
      subscribed,

      videoPlayerRef,
      containerRef,

      store,

      updateMode,
      // 2023
      download,
    };
  },
});
