
import { defineComponent } from "vue";
import PageTitle from "@/components/common/PageTitle.vue";
import { topPage } from "@/utils/utils";

export default defineComponent({
  components: {
    PageTitle,
  },
  setup() {
    return {
      topPage,
    };
  },
});
