export const liveStatus = {
  beforeLoad: 0,
  beforeSell: 10,
  beforeLive: 20,
  onLive: 30,
  AfterLive: 40,
  onVOD: 50,
  EndOfLive: 100,

  // 0 (beforeDataLoad)
  // 10 (beforeSell)
  // sellStartAt: Timestamp　　//販売開始
  // 20 (beforeLive)
  // onLiveStreaming: boolean //  (ライブ開始、終了時間に関わらず、このフラグがセットされているとライブがStreamingされる
  // 30 (onLive)
  // liveStartAt: Timestamp    // ライブ開始
  // この間は、どのような状態でもライブ中と判断する
  // liveEndAt: Timestamp    //ライブ終了予定時間　（事故防止のために、終了時間＋n時間後にはストリームがあっても終了させる）
  // 40 (AfterLive)
  // VODFlag: boolean // Whether to do VOD　VODがない場合には、ここで終了
  // isVODReady: boolean // set true if VOD is ready.　VODの用意ができたらVODスタート
  //  50 (onVOD)
  // VODEndAt: Timestamp   // VOD終了予定時間
  // 100 (EndOfLive)
};
