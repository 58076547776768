
import { defineComponent } from "vue";

import { auth, db } from "@/utils/firebase9";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  sendEmailVerification,
} from "firebase/auth";

import { useRouter, useRoute } from "vue-router";
import PageTitle from "@/components/common/PageTitle.vue";
import {
  getRedirectPath,
  topPage,
  isNull,
  redirectMyPageIfUser,
} from "@/utils/utils";
import { doc, getDoc } from "firebase/firestore";
import { useStore } from "vuex";

export default defineComponent({
  name: "Index",
  components: {
    PageTitle,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const redirectPath = getRedirectPath(route);
    const store = useStore();

    redirectMyPageIfUser();

    const authentication = async (provider) => {
      try {
        const res = await signInWithPopup(auth, provider);
        if (!res.user.emailVerified) {
          await sendEmailVerification(res.user);
        }
        const uid = res.user.uid;
        const data = (await getDoc(doc(db, `users/${uid}`))).data();
        store.commit("setDbUser", data || null);
        if (!res.user.emailVerified || isNull((data || {}).nickname)) {
          router.push("/account/mypage");
        } else if (redirectPath) {
          router.push(redirectPath);
        } else {
          router.push(topPage);
        }
      } catch (error) {
        if (error.code === "auth/account-exists-with-different-credential") {
          alert(
            error.email +
              "は、すでに他のログイン方法で使われています。すでに登録済みの他のログイン方法を利用してください。",
          );
        } else {
          alert(error.message);
        }
      }
    };
    const googleSignin = async () => {
      const provider = new GoogleAuthProvider();
      provider.addScope("email");
      authentication(provider);
    };
    const fbSignin = async () => {
      const provider = new FacebookAuthProvider();
      provider.addScope("email");
      authentication(provider);
    };

    return {
      redirectPath,
      googleSignin,
      fbSignin,
    };
  },
});
