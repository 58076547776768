import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5096a84a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "App",
  id: "app"
}
const _hoisted_2 = { class: "App__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_component = _resolveComponent("header-component")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_header_component, { ref: "header" }, null, 512),
    _createElementVNode("main", _hoisted_2, [
      (_openBlock(), _createBlock(_Suspense, null, {
        default: _withCtx(() => [
          (_ctx.user !== undefined)
            ? (_openBlock(), _createBlock(_component_router_view, {
                key: 0,
                onVideomount: _ctx.setHeaderHasVideo
              }, null, 8, ["onVideomount"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    ])
  ]))
}