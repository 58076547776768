
import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";

import PageTitle from "@/components/common/PageTitle.vue";

export default defineComponent({
  setup() {
    useHead({
      title: "推奨環境 - LiveMedia Space",
    });
    return {};
  },
  components: {
    PageTitle,
  },
});
