
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "SearchBox",
  emits: ["update:input"],

  setup(props, { emit }) {
    const input = ref("");
    watch(input, (value) => {
      console.log(value);
      emit("update:input", value);
    });
    return {
      input,
    };
  },
});
