
import moment from "moment";
import { defineComponent } from "vue";
import { contentUrlPrefix } from "@/utils/utils";

export default defineComponent({
  name: "LiveList",
  props: {
    contents: Array,
  },
  setup() {
    return {
      contentUrlPrefix,
      formatedDate: (d) => {
        if (d) {
          return moment(d.toDate()).format("YYYY/MM/DD HH:mm");
        }
        return "";
      },
      formatedDate2: (d) => {
        if (d) {
          return moment(d.toDate()).format("HH:mm");
        }
        return "";
      },
    };
  },
});
