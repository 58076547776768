import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_reset_password = _resolveComponent("reset-password")!
  const _component_verify_email = _resolveComponent("verify-email")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.mode == 'resetPassword')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_reset_password)
        ]))
      : _createCommentVNode("", true),
    (_ctx.mode == 'verifyEmail')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_verify_email)
        ]))
      : _createCommentVNode("", true)
  ]))
}