// for prod
export const firebaseConfig = {
  apiKey: "AIzaSyAkRiwJyGndE_ltJyZLhg_1GDnPdGE-VOg",
  authDomain: "livemedia-viewer-prod.firebaseapp.com",
  databaseURL: "https://livemedia-viewer-prod.firebaseio.com",
  projectId: "livemedia-viewer-prod",
  storageBucket: "livemedia-viewer-prod.appspot.com",
  messagingSenderId: "836750563648",
  appId: "1:836750563648:web:0a49a13e482c4d8ecd82a5",
};

export const stripePublicKey = "pk_live_bByBMevjVvOs8HYx1sooKHcb00S8CMlh64";

export const lineConfig = {
  loginChannelId: "1654480372",
};

export const sentry = {
  dsn: "https://e1995100f46a410892ab7ee96bc3d54f@o437868.ingest.sentry.io/5400923",
};

export const testContentsPath =
  "/live/livemedia/20210201/wZrCkoq-UKOktp2nwyBsgA7w";
