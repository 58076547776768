
import { defineComponent, ref } from "vue";
import { useHead } from "@vueuse/head";

import PageTitle from "@/components/common/PageTitle.vue";

export default defineComponent({
  components: {
    PageTitle,
  },
  setup() {
    useHead({
      title: "よくある質問 - LiveMedia Space",
    });
    const items = [
      {
        question: "会員登録メールが届きません",
        answer:
          "会員登録メールが正常に届かない場合「noreply@livemedia-viewer-prod.firebaseapp.com」の 受信設定をお願いいたします。",
      },
      {
        question: "パスワードを忘れてしまいました。",
        answer:
          '<a href="/account/resetpasswd">こちらのページ</a>よりパスワードを変更ください。',
      },
      {
        question: "パスワードの変更をしたい。",
        answer:
          '<a href="/account/resetpasswd">こちらのページ</a>よりパスワードを変更ください。',
      },
      {
        question: "ログインをすることができません。",
        answer:
          '登録したメールアドレスとパスワードを再度ご確認ください。<br><a href="/account/resetpasswd">パスワードの再設定</a>をして再度ログインをお試しください。',
      },
      {
        question: "アカウント登録しないと視聴できませんか？",
        answer: "アカウント登録をしないと視聴することはできません。",
      },
      {
        question: "うまく動画視聴できません。",
        answer:
          '<a href="/docs/requirements">サイトの推奨環境</a>をご確認のうえ、再度ご視聴ください。',
      },
      {
        question: "複数のブラウザから同時視聴することは可能ですか？",
        answer:
          "複数のブラウザから同時視聴することはできません。直近ログインしたブラウザからの視聴が優先されます。",
      },
      {
        question: "動画の保存をすることはできますか？",
        answer:
          "DRM（コンテンツ保護）を使用した動画をストリーミングを配信しておりますのでDownloadが許可されたコンテンツ以外、動画を保存することはできません。<br>スクリーンレコードなどを利用しての保存などはご遠慮ください。",
      },
      {
        question:
          "アーカイブの動画ファイルをダウンロードしたけど保存場所がわからない",
        answer:
          "※Downloadが許可されたコンテンツのみ保存可能となります。<br><br>＜iPhone＞<br>・SafariでDownloadした場合<br>→「ファイル」アプリ内の「このiPhone内」に保存されます。<br>※設定によっては別のフォルダ内に保存がされる場合がございます。<br><br>・ChromeでDownloadした場合<br>→「ファイル」アプリ内の「このiPhone内」→「Chrome」フォルダに保存されます。<br>※設定によっては別のフォルダ内に保存がされる場合がございます。<br><br>＜Android＞<br>・ChromeでDownloadした場合<br>→「ファイルマネージャー」内の「ビデオ」に保存されます。<br>※設定によっては別のフォルダ内に保存がされる場合がございます。<br><br>＜Mac＞<br>・Safari、ChromeでDownloadした場合<br>→「Download」をクリックすると指定したフォルダ内に保存されます。<br><br>＜Windows＞<br>・Edge、ChromeでDownloadした場合<br>→「Download」をクリックすると指定したフォルダ内に保存されます。",
      },
      {
        question: "海外で視聴することは可能ですか？",
        answer:
          "海外でも視聴することは可能です。推奨環境を満たしていても、海外の通信環境によっては正常に視聴することができない可能性がございます。",
      },
      {
        question: "支払い方法は何がありますか？",
        answer:
          "クレジットカード（VISA、MasterCard、American Express、JCB）のみの対応となります。<br>プリペイド式のクレジットカードは非対応となります。予めご了承ください。",
      },
      {
        question: "購入履歴を確認したい。",
        answer: "ログインしマイページより確認することができます。",
      },
      {
        question: "アカウントを退会してほしい",
        answer:
          '<a href="/docs/asks">お問い合わせフォーム</a>より、退会を希望するメールアドレスとアカウント退会を希望する旨をご連絡ください。',
      },
      {
        question: "お問い合わせフォーム",
        answer: '<a href="/docs/asks">こちら</a>からお問い合わせください。',
      },
    ].map(
      (
        item: {
          question: string;
          answer: string;
          id: number;
          isActive: boolean;
        },
        k,
      ) => {
        item.id = k;
        item.isActive = false;
        return item;
      },
    );
    return {
      items: ref(items),
    };
  },
});
