
import { defineComponent, computed, ref } from "vue";

import { useRouter } from "vue-router";

import { requireSignin, useUser } from "@/utils/utils";

import isEmail from "validator/lib/isEmail";
import PageTitle from "@/components/common/PageTitle.vue";
import { auth } from "@/utils/firebase";

export default defineComponent({
  name: "ChangeEmail",
  components: {
    PageTitle,
  },
  setup() {
    const router = useRouter();
    const user = useUser();

    const email = ref("");
    const password = ref("");
    const isLoading = ref(false);
    const isSuccess = ref(false);

    requireSignin(user.value, router, location);

    const errors = computed(() => {
      const _errors: { [key: string]: string[] } = {};
      if (password.value.length < 6) {
        _errors.password = ["account.error.currentPassword.tooshort"];
      }
      if (!isEmail(email.value)) {
        _errors.email = ["account.error.email.invalid"];
      }
      return _errors;
    });

    const updateEmail = async () => {
      try {
        const userCredential = await auth.signInWithEmailAndPassword(
          user.value.email,
          password.value,
        );
        if (userCredential.user) {
          userCredential.user.updateEmail(email.value);
          isSuccess.value = true;
        } else {
          // error case
          // userCredential.code;
          console.log("error");
        }
      } catch (e) {
        // TODO: show error message
        console.log(e);
      }
    };

    return {
      user,
      email,
      password,
      isLoading,
      isSuccess,
      errors,

      updateEmail,
    };
  },
});
