import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9016544a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Checkbox" }
const _hoisted_2 = ["id", "checked", "value"]
const _hoisted_3 = {
  class: "Checkbox__label",
  for: "save_card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: "Checkbox__checkbox",
      type: "checkbox",
      id: _ctx.id,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.update && _ctx.update(...args))),
      checked: _ctx.modelValue,
      value: _ctx.modelValue
    }, null, 40, _hoisted_2),
    _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.label), 1)
  ]))
}