import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2252664"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Form" }
const _hoisted_2 = {
  key: 0,
  class: "Form__head"
}
const _hoisted_3 = { class: "Form__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.head)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.head), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}