
import { defineComponent } from "vue";
import { useHead } from "@vueuse/head";

import PageTitle from "@/components/common/PageTitle.vue";
import { shareUrl } from "@/utils/utils";

export default defineComponent({
  components: {
    PageTitle,
  },
  setup() {
    useHead({
      title: "特定商取引法表示 - LiveMeida Space",
    });
    return {
      contactUrl: shareUrl + "docs/asks",
      contactPath: "/docs/asks",
    };
  },
});
