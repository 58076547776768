import { createStore } from "vuex";
import { User } from "firebase/auth";
import moment from "moment";

interface State {
  user: null | undefined | User;
  dbUser: null | undefined;
  orientation: {
    angle: number | null | undefined;
    isPortrait: boolean | null | undefined;
  };
  isMobile: null | undefined | boolean;
  date: moment.Moment;
  componentName: string;
}

export default createStore<State>({
  state: {
    user: undefined,
    dbUser: undefined,
    orientation: {
      angle: 0,
      isPortrait: true,
    },
    isMobile: undefined,
    date: moment(),
    componentName: "",
  },
  getters: {
    isPasswordUser: (state, getters) => {
      return getters.providerId === "password";
    },
    providerId: (state) => {
      return (state.user?.providerData || [])[0]?.providerId;
    },
  },
  mutations: {
    setUser(state, user: User | null) {
      state.user = user;
    },
    setDbUser(state, user: any | null) {
      state.dbUser = user;
    },
    setOrientation(state, angle: number) {
      state.orientation.angle = angle;
      state.orientation.isPortrait = angle === 0 ? true : false;
    },
    setDevice(state, isMobile: boolean) {
      state.isMobile = isMobile;
    },
    updateDate(state) {
      state.date = moment();
    },
    setComponentName(state, componentName: string) {
      state.componentName = componentName;
    },
  },
  actions: {},
  modules: {},
});
