
import { defineComponent, ref } from "vue";

import { auth } from "@/utils/firebase9";
import { signInWithEmailAndPassword } from "firebase/auth";
import PageTitle from "@/components/common/PageTitle.vue";

import { useRouter } from "vue-router";
import { topPage, redirectMyPageIfUser } from "@/utils/utils";

export default defineComponent({
  name: "Signin",
  components: {
    PageTitle,
  },
  setup() {
    const router = useRouter();

    const email = ref("");
    const password = ref("");
    const isLoading = ref(false);
    const errors = ref({});

    redirectMyPageIfUser();
    const onSignin = async () => {
      try {
        isLoading.value = true;
        errors.value = {};

        await signInWithEmailAndPassword(auth, email.value, password.value);
        router.push(topPage);

        console.log("onSignin success");
      } catch (error) {
        console.log("onSignin failed", error.code, error.message);
        const errorCode = "account.error.code." + error.code;
        if (error.code === "auth/wrong-password") {
          errors.value = { password: [errorCode] };
        } else {
          errors.value = { email: [errorCode] };
        }
      } finally {
        isLoading.value = false;
      }
    };
    return {
      onSignin,

      email,
      password,
      isLoading,
      errors,
    };
  },
});
