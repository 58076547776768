import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce516a7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "List" }
const _hoisted_2 = { class: "Layout Layout--m48" }
const _hoisted_3 = { class: "Layout__item" }
const _hoisted_4 = { class: "Layout__item" }
const _hoisted_5 = { class: "Layout Layout--m16" }
const _hoisted_6 = { class: "Layout__item List__text" }
const _hoisted_7 = { class: "Layout__item List__search" }
const _hoisted_8 = { class: "Layout__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Category = _resolveComponent("Category")!
  const _component_page_title = _resolveComponent("page-title")!
  const _component_SearchBox = _resolveComponent("SearchBox")!
  const _component_live_list = _resolveComponent("live-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Category, { categories: _ctx.categories }, null, 8, ["categories"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_page_title, {
            title: 
              _ctx.categoryId === 'archive' ? 'アーカイブ配信' : 'ライブ配信予定'
            
          }, null, 8, ["title"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subCategoryData, (line, k) => {
                return (_openBlock(), _createElementBlock("div", { key: k }, _toDisplayString(line), 1))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_SearchBox, {
                input: _ctx.searchInput,
                "onUpdate:input": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchInput) = $event))
              }, null, 8, ["input"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_live_list, {
            contents: _ctx.searchInput == '' ? _ctx.contents : _ctx.searchResult
          }, null, 8, ["contents"])
        ])
      ])
    ])
  ]))
}