
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";

import PageTitle from "@/components/common/PageTitle.vue";
import { auth } from "@/utils/firebase";

export default defineComponent({
  name: "AccountAction",
  components: {
    PageTitle,
  },
  setup() {
    const route = useRoute();
    const code = route.query.oobCode as string;

    const submitting = ref(false);
    const password = ref("");
    const confirmPassword = ref("");
    const isSuccess = ref(false);
    const error = ref("");

    const errors = computed(() => {
      const _errors: { [key: string]: string[] } = {};
      if (password.value !== confirmPassword.value) {
        _errors.confirm = ["account.error.password.mismatch"];
      }
      if (password.value.length < 8) {
        _errors.password = ["account.error.password.tooshort"];
      }
      if (password.value !== null && !password.value.match(/^[A-Za-z0-9]*$/)) {
        _errors.password = ["account.error.password.validCharactor"];
      }
      if (error.value) {
        _errors.firebase = ["account.error.code." + error.value];
      }
      return _errors;
    });

    const resetPassword = async () => {
      submitting.value = true;
      try {
        const res = await auth.confirmPasswordReset(code, password.value);
        console.log(res);
        isSuccess.value = true;
      } catch (e) {
        console.log(e);
        error.value = e.code;
      }
      submitting.value = true;
    };
    return {
      submitting,
      password,
      confirmPassword,
      isSuccess,
      errors,
      resetPassword,
    };
  },
});
