
import { defineComponent, ref } from "vue";
import Chat from "@/components/Chat.vue";

export default defineComponent({
  props: {
    content: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  components: {
    Chat,
  },
  emits: ["updateMode"],
  setup(props, context) {
    const chatRef = ref();

    const scrollChatMessages = () => {
      context.emit("updateMode", "chat");
      chatRef.value.scrollMessages();
    };
    const setInfo = () => {
      context.emit("updateMode", "info");
    };
    return {
      chatRef,
      scrollChatMessages,
      setInfo,
    };
  },
});
