import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import store from "../store";
import moment from "moment";

export const topPage = "/live/lists";

export const getRedirectPath = (route) => {
  return route.query.redirect;
};

export const useUserPath = () => {
  const uid = useUid();
  return computed(() => (uid.value ? `users/${uid.value}` : null));
};

export const useUserSecretPath = () => {
  const uid = useUid();
  return computed(() => {
    return uid.value ? `users/${uid.value}/secrets/token` : null;
  });
};

export const useUser = () => {
  const _store = useStore();
  return computed(() => _store.state.user);
};
export const useDbUser = () => {
  const _store = useStore();
  return computed(() => _store.state.dbUser);
};
// for sign up page
export const redirectMyPageIfUser = () => {
  const user = useUser();
  const router = useRouter();

  if (user.value && user.value.uid) {
    router.push("/account/mypage");
  }
};

export const useNickname = () => {
  const dbUser = useDbUser();
  return computed(() => dbUser.value?.nickname);
};

export const useUserEmail = () => {
  const _user = useUser();
  return computed(() => {
    const user = _user.value;
    if (user) {
      if (user.email) {
        return user.email;
      }
      if (
        user.providerData &&
        user.providerData[0] &&
        user.providerData[0] &&
        user.providerData[0].email
      ) {
        return user.providerData[0].email;
      }
    }
    return "";
  });
};
export const useAuthenticated = () => {
  const user = useUser();
  return computed(() => {
    return !!user.value;
  });
};

export const useUid = () => {
  const user = useUser();
  return computed(() => {
    return user.value?.uid;
  });
};
/*
    topPage() {
      return "/live/lists";
    },
*/
export const useContentId = () => {
  const route = useRoute();
  return computed(() => {
    return String(route.params.contentId);
  });
};

export const useCategoryIds = () => {
  const route = useRoute();
  const subSubCategoryId = computed(() => {
    return String(route.params.subsubcategory);
  });
  const subCategoryId = computed(() => {
    return String(route.params.subcategory);
  });
  const categoryId = computed(() => {
    return String(route.params.category);
  });
  return {
    subSubCategoryId,
    subCategoryId,
    categoryId,
  };
};

export const useComponentName = () => {
  return computed(() => {
    return store.state.componentName;
  });
};
export const useLoginLink = () => {
  const componentName = useComponentName();
  if (componentName.value === "ContentsPlayer") {
    return "/account?redirect=" + encodeURIComponent(location.pathname);
  } else {
    return "/account";
  }
};

/*
    moment(day: any) {
      return moment(day);
    },
*/

export const requireSignin = (user, router, location) => {
  if (!user) {
    router.replace("/account?redirect=" + location.pathname);
  }
  return !user;
};

export const isNull = <T>(value: T) => {
  return value === null || value === undefined || String(value) === "";
};
export const isEmpty = <T>(value: T) => {
  return (
    value === null || value === undefined || Object.keys(value).length === 0
  );
};

export const shareUrl = location.protocol + "//" + location.host + "/";

export const doc2data = () => {
  return (doc: any) => {
    const data = doc.data();
    data.id = doc.id;
    return data;
  };
};

export const array2obj = (array: any[]) => {
  return array.reduce((tmp, current) => {
    tmp[current.id] = current;
    return tmp;
  }, {});
};

export const contentUrlPrefix = (content: any) => {
  if (content.liveStartAt) {
    return `/live/${content.livehousename || "livehouse"}/${moment(
      content.liveStartAt.toDate(),
    ).format("YYYYMMDD")}/${content.id}`;
  }
  return "";
};

export const checkNicknameAndRedirect = (
  router: any,
  componentName: string,
  data: any,
  user: any,
  to: string,
) => {
  const redirectPath = "/account/mypage";
  if (isNull((data || {}).nickname) || !(user || {}).emailVerified) {
    // is not set nickname
    if (to !== redirectPath) {
      // this is not mypage
      if (componentName === "ContentsPlayer") {
        router.push(
          redirectPath + "?redirect=" + encodeURIComponent(location.pathname),
        );
      } else {
        router.push(redirectPath);
      }
      return true;
    }
  }
  return false;
};

export const sleep = (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const timeFormat = (time) => {
  return (time?.seconds ? moment.unix(time?.seconds) : moment()).format(
    "MM/DD HH:mm:ss",
  );
};

export const chunk = (arr: string[], chunkSize: number) => {
  const ret: string[][] = [];
  const len = arr.length;
  for (let i = 0; i < len; i += chunkSize) {
    const tmp = arr.slice(i, i + chunkSize);
    ret.push(tmp);
  }
  return ret;
};

// for debug
export const randomStr = () => {
  const str =
    "あいうえおかきくけこさしすせそたちってとなにぬねのはひふへほまみむめもやゆよわをん".split(
      "",
    );
  return Array.from(new Array(50))
    .map(() => {
      return str[Math.round(Math.random() * str.length)];
    })
    .join("");
};
