
import { defineComponent, computed } from "vue";

import { useRoute } from "vue-router";

import ResetPassword from "./Actions/ResetPassword.vue";
import VerifyEmail from "./Actions/VerifyEmail.vue";

export default defineComponent({
  name: "AccountAction",
  components: {
    ResetPassword,
    VerifyEmail,
  },
  setup() {
    const route = useRoute();
    const mode = computed(() => {
      return route.query.mode;
    });
    return { mode };
  },
});
