
import { defineComponent } from "vue";
import { useAuthenticated } from "@/utils/utils";

export default defineComponent({
  name: "HeaderNav",
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close"],
  setup() {
    const authenticated = useAuthenticated();
    return {
      authenticated,
    };
  },
});
