import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25755fd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Category" }
const _hoisted_2 = { class: "Category__body" }
const _hoisted_3 = {
  key: 0,
  class: "Category__body"
}
const _hoisted_4 = {
  key: 1,
  class: "Category__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, k) => {
        return (_openBlock(), _createElementBlock("div", { key: k }, [
          _createVNode(_component_router_link, {
            to: `/live/lists/${category.id}`,
            class: _normalizeClass(["Category__link", category.id === _ctx.categoryId ? 'Category__link--selected' : ''])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(category.name), 1)
            ]),
            _: 2
          }, 1032, ["to", "class"])
        ]))
      }), 128))
    ]),
    (_ctx.categoryId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subcategories, (subcategory, k) => {
            return (_openBlock(), _createElementBlock("div", { key: k }, [
              _createVNode(_component_router_link, {
                to: `/live/lists/${_ctx.categoryId}/${subcategory.id}`,
                class: _normalizeClass([
            subcategory.id === _ctx.subCategoryId ? 'Category__link--selected' : ''
          , "Category__link"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(subcategory.name), 1)
                ]),
                _: 2
              }, 1032, ["to", "class"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.categoryId && _ctx.subCategoryId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subsubcategories, (subsubcategory, k) => {
            return (_openBlock(), _createElementBlock("div", { key: k }, [
              _createVNode(_component_router_link, {
                class: _normalizeClass(["Category__link", 
            subsubcategory.id === _ctx.subSubCategoryId
              ? 'Category__link--selected'
              : ''
          ]),
                to: `/live/lists/${_ctx.categoryId}/${_ctx.subCategoryId}/${subsubcategory.id}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(subsubcategory.name), 1)
                ]),
                _: 2
              }, 1032, ["class", "to"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}