
import { defineComponent, ref, watch } from "vue";

import { db } from "@/utils/firebase9";
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";

import { doc2data, useCategoryIds } from "@/utils/utils";

export default defineComponent({
  name: "CategoryList",
  props: {
    categories: Array,
  },
  setup() {
    const { categoryId, subCategoryId, subSubCategoryId } = useCategoryIds();

    const subcategories = ref([]);
    const updateSubcategory = async () => {
      if (categoryId.value) {
        subcategories.value = (
          await getDocs(
            query(
              collection(db, `categories/${categoryId.value}/subcategories`),
              where("isPublic", "==", true),
              orderBy("order", "asc"),
              limit(20),
            ),
          )
        ).docs.map(doc2data());
      } else {
        subcategories.value = [];
      }
    };
    updateSubcategory();
    watch(categoryId, () => {
      updateSubcategory();
    });

    const subsubcategories = ref([]);
    const updateSubSubcategory = async () => {
      if (categoryId.value && subCategoryId.value) {
        subsubcategories.value = (
          await getDocs(
            query(
              collection(
                db,
                `categories/${categoryId.value}/subcategories/${subCategoryId.value}/subsubcategories`,
              ),
              where("isPublic", "==", true),
              orderBy("order", "asc"),
              limit(20),
            ),
          )
        ).docs.map(doc2data());
      } else {
        subsubcategories.value = [];
      }
    };
    updateSubSubcategory();
    watch([categoryId, subCategoryId], () => {
      updateSubSubcategory();
    });

    return {
      subcategories,
      subsubcategories,

      categoryId,
      subCategoryId,
      subSubCategoryId,
    };
  },
});
