
import { defineComponent } from "vue";
// @ is an alias to /src
// import BackgroundImage from "@/assets/img/bg_home.jpg";

export default defineComponent({
  name: "Home",
  data() {
    return {
      BackgroundImage: "../assets/img/bg_home.jpg",
    };
  },
});
