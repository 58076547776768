
import { defineComponent, computed, ref } from "vue";
import Modal from "@/components/common/Modal.vue";

import { db } from "@/utils/firebase9";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";

import { SubscribeContentsWithTicketFunctions } from "@/utils/firebase/functions";

import { contentUrlPrefix, useUid, doc2data } from "@/utils/utils";

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const userTickets = ref([]);

    const ticket = ref();
    const modalOpen = ref(false);
    const loading = ref(false);

    const uid = useUid();

    const ticketRightId = computed(() => {
      const ticket = props.content.rights.find((right) => {
        return right.id.startsWith("ticket_");
      });
      if (ticket) {
        return ticket.id;
      }
      return null;
    });
    const loadRights = async () => {
      if (uid.value) {
        console.log(`/users/${uid.value}/tickets`, ticket);
        const collect = await getDocs(
          query(
            collection(db, `/users/${uid.value}/tickets`),
            where("ticketId", "==", ticket.value.id),
          ),
        );
        userTickets.value = collect.docs.map(doc2data());
      }
    };
    const loadTicket = async () => {
      const rightDoc = await getDoc(doc(db, `rights/${ticketRightId.value}`));
      if (!rightDoc.exists()) {
        return;
      }
      const ticketId = rightDoc.data().ticketId;
      const ticketDoc = await getDoc(doc(db, `tickets/${ticketId}`));
      if (!ticketDoc.exists()) {
        return;
      }
      ticket.value = ticketDoc.data();
      ticket.value.id = ticketDoc.id;
    };
    const pageLoading = ref(true);
    (async () => {
      await loadTicket();
      await loadRights();
      pageLoading.value = false;
    })();
    const hasExtraTicket = computed(() => {
      if (userTickets.value.length === 0) {
        return false;
      }
      return userTickets.value.some((userTicket) => {
        return userTicket.restOfTickets > 0;
      });
    });
    const userTicket = computed(() => {
      const extraTicket = userTickets.value.find((userTicket) => {
        return userTicket.restOfTickets > 0;
      });
      return extraTicket;
    });
    const ticketConfirm = () => {
      modalOpen.value = true;
    };

    const postTicket = async () => {
      const extraTicket = userTickets.value.find((userTicket) => {
        return userTicket.restOfTickets > 0;
      });
      const postData = {
        userTicketId: extraTicket.id,
        rightsId: ticketRightId.value,
      };
      loading.value = true;

      try {
        await SubscribeContentsWithTicketFunctions(postData);
        location.reload();
      } catch (e) {
        // TODO error;
        console.log(e);
      }
      loading.value = false;
    };

    return {
      modalOpen,
      contentUrlPrefix,

      ticket,
      ticketConfirm,
      hasExtraTicket,
      userTicket,

      pageLoading,
      loading,
      postTicket,
    };
  },
});
