
import {
  defineComponent,
  watch,
  ref,
  onMounted,
  onUnmounted,
  computed,
} from "vue";

import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import { UAParser } from "ua-parser-js";
import HeaderComponent from "@/components/common/Header.vue";
import { db, auth } from "@/utils/firebase9";
import { signOut } from "firebase/auth";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import { sha512 } from "js-sha512";

import {
  getRedirectPath,
  checkNicknameAndRedirect,
  useUser,
  useUserPath,
  useUserSecretPath,
} from "@/utils/utils";

import { useHead } from "@vueuse/head";

export default defineComponent({
  components: {
    HeaderComponent,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const header = ref();

    const userDetacher = ref([]);
    const userPath = useUserPath();
    const userSecretPath = useUserSecretPath();
    const user = useUser();

    const setHeaderHasVideo = () => {
      header.value.hasVideo = true;
    };

    const componentName = computed(() => {
      return router.currentRoute.value.name;
    });

    useHead({
      title: "LiveMedia",
      script: [{ src: "https://js.stripe.com/v3/" }],
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href: "/favicon.ico",
        },
        {
          rel: "apple-touch-icon",
          href: "/apple-touch-icon.png",
        },
        {
          rel: "icon",
          sizes: "192x192",
          type: "image/png",
          href: "/android-touch-icon.png",
        },
        {
          rel: "icon",
          sizes: "512x512",
          type: "image/png",
          href: "/android-touch-icon.png",
        },
      ],
    });

    watch(
      () => route.path,
      (to, from) => {
        store.commit("setComponentName", componentName.value);
        if (to !== from) {
          header.value.init();
        }
        if (store.state.user) {
          checkNicknameAndRedirect(
            router,
            store.state.componentName,
            store.state.dbUser,
            store.state.user,
            to,
          );
        }
      },
    );

    store.commit("setComponentName", componentName.value);

    const authDetacher = auth.onAuthStateChanged((user) => {
      store.commit("setUser", user);
    });
    watch(user, async () => {
      resetUserDetacher();
      if (user.value) {
        const hash = sha512(user.value.refreshToken);
        await setDoc(doc(db, userSecretPath.value), { hash });
        const userSecretDetacher = onSnapshot(
          doc(db, userSecretPath.value),
          (result) => {
            const dbhash = result.data().hash;
            if (dbhash !== hash) {
              signOut(auth);
              router.replace("/account");
              location.reload();
            }
          },
        );
        userDetacher.value.push(userSecretDetacher);
        const _userDetacher = onSnapshot(doc(db, userPath.value), (result) => {
          const data = result.exists ? result.data() : null;
          store.commit("setDbUser", data);
          if (location.pathname == "/account/action") {
            return;
          }
          if (
            !checkNicknameAndRedirect(
              router,
              store.state.componentName,
              data,
              user.value,
              location.pathname,
            )
          ) {
            const redirectPath = getRedirectPath(route);
            if (redirectPath) {
              router.push(redirectPath);
            } else {
              if (location.pathname.startsWith("/account")) {
                // router.push(topPage);
              }
            }
          }
        });
        userDetacher.value.push(_userDetacher);
      }
    });
    const resetUserDetacher = () => {
      if (userDetacher.value) {
        userDetacher.value.map((a) => {
          a();
        });
        userDetacher.value = [];
      }
    };

    const detectDevice = () => {
      const uaParser = new UAParser(window.navigator.userAgent);
      const result = uaParser.getDevice();
      // TODO: type is undefined? Why??
      const isMobile = result.type === "mobile" ? true : false;
      store.commit("setDevice", isMobile);
    };
    detectDevice();

    const timerId = window.setInterval(() => {
      store.commit("updateDate");
    }, 20 * 1000);

    const detectOrientation = () => {
      // mac, iOSともにscreen.orientation未対応
      // -> 代わりにwindow.orientationを参照するが、mac safariはundefinedを返す
      // -> PC表示は常にportraitでよいので、0を代入
      let screenAngle =
        screen && screen.orientation && screen.orientation.angle;
      if (screenAngle === undefined) {
        screenAngle = window.orientation || 0;
      }
      store.commit("setOrientation", screenAngle);
    };

    onUnmounted(() => {
      if (timerId) {
        window.clearInterval(timerId);
      }
      if (authDetacher) {
        authDetacher();
      }
      resetUserDetacher();
      window.removeEventListener("orientationchange", detectOrientation, false);
    });

    onMounted(() => {
      detectOrientation();
      window.addEventListener("orientationchange", detectOrientation, false);
    });

    return {
      header,
      setHeaderHasVideo,
      user,
    };
  },
});
