
import { defineComponent } from "vue";
import PageTitle from "@/components/common/PageTitle.vue";

export default defineComponent({
  components: {
    PageTitle,
  },
  setup() {
    return {};
  },
});
