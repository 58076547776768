import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b7b89e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "RegisteredCreditCard" }
const _hoisted_2 = { class: "RegisteredCreditCard__number" }
const _hoisted_3 = { class: "RegisteredCreditCard__exp" }
const _hoisted_4 = {
  key: 0,
  class: "RegisteredCreditCard__icon fab fa-cc-visa"
}
const _hoisted_5 = {
  key: 1,
  class: "RegisteredCreditCard__company"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, " XXXX XXXX XXXX " + _toDisplayString(_ctx.source.last4), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.source.exp_month) + " / " + _toDisplayString(_ctx.source.exp_year), 1),
    (_ctx.source.brand === 'Visa')
      ? (_openBlock(), _createElementBlock("i", _hoisted_4))
      : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.source.brand), 1))
  ]))
}