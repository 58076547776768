import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-338cf6c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "FAQ" }
const _hoisted_2 = { class: "Layout Layout--m48" }
const _hoisted_3 = { class: "Layout__item" }
const _hoisted_4 = { class: "Layout__item" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_title = _resolveComponent("page-title")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_page_title, { title: "よくある質問" })
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("dl", {
            class: "FAQ__item",
            key: item.id
          }, [
            _createElementVNode("dt", {
              class: _normalizeClass(["FAQ__question", { 'is-active': item.isActive }]),
              onClick: ($event: any) => (item.isActive = !item.isActive)
            }, _toDisplayString(item.question), 11, _hoisted_5),
            _createElementVNode("dd", {
              class: "FAQ__answer",
              innerHTML: item.answer
            }, null, 8, _hoisted_6)
          ]))
        }), 128))
      ])
    ])
  ]))
}