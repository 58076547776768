
import { defineComponent } from "vue";
import { h } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  render() {
    const nodes = this.text
      .split(/(https?:\/\/[\w!?/+\-_~=;.,*&@#$%()'[\]]+)|(\n)/gi)
      .filter((x) => x !== undefined)
      .map((x) => {
        if (x === "\n") {
          return h("br");
        }
        if (/(https?:\/\/[\w!?/+\-_~=;.,*&@#$%()'[\]]+)/.test(x)) {
          return h("a", { attrs: { href: x } }, x);
        } else {
          return h("span", {}, x);
        }
      }, this);
    return h("span", nodes);
  },
});
