
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import moment from "moment";

import { liveStatus } from "@/utils/constant";
import {
  contentUrlPrefix,
  useAuthenticated,
  useLoginLink,
} from "@/utils/utils";
import { testContentsPath } from "@/config/project";

import TicketButton from "./TicketButton.vue";

export default defineComponent({
  components: {
    TicketButton,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    sellEndAt: {
      type: Object,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    subscribed: {
      type: Boolean,
      required: true,
    },
    isRestrictContents: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const authenticated = useAuthenticated();
    const loginLink = useLoginLink();

    // Playerからstatusをもらって照合
    const beforeSell = computed(() => {
      return props.status === liveStatus.beforeSell;
    });
    const beforeLive = computed(() => {
      return props.status === liveStatus.beforeLive;
    });
    const afterLive = computed(() => {
      return props.status === liveStatus.AfterLive;
    });
    const afterSell = computed(() => {
      const now = store.state.date;
      return now > props.sellEndAt;
    });
    const EndOfLive = computed(() => {
      return props.status === liveStatus.EndOfLive;
    });

    const router = useRouter();
    const goTestContents = () => {
      router.push({ path: testContentsPath });
    };

    /*
    const isTicketContents = computed(() => {
      return props.content.rights.some((right) => {
        return right.id.startsWith("ticket_");
      });
    });
    */
    const isTicketContents = computed(() => {
      return (props.content.rights[0] || {}).id.startsWith("ticket_");
    });

    return {
      beforeSell,
      beforeLive,
      afterLive,
      afterSell,
      EndOfLive,

      contentUrlPrefix,
      authenticated,
      loginLink,
      goTestContents,

      moment,

      isTicketContents,
    };
  },
});
